import React from "react";
import {
  DivComercial,
  DivOrgComercial,
  TitleComercial,
} from "./CommercialStyle";

import Order from "../../components/Forms/Order/Order";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { allProducts } from "../../store/registers/products/products.actions";
import { useState } from "react";
import { useEffect } from "react";
import { allClients } from "../../store/registers/clients/clients.actions";

export default function Commercial() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [listProduct, setListProduct] = useState([]);
  const [listClient, setListClient] = useState([]);
  const [reloadingClient, setReloadingClient] = useState(false)

  let { orderChange } = 0;
  orderChange = location.state || {};
  // ! Por em quanto não achei uma solução melhor para limpar a lista quando for alterar - 05/07/2023
  window.history.replaceState(orderChange, null);
  const infoOrderChange = orderChange;

  const getProductList = async () => {
    const listProducts = await dispatch(allProducts());
    setListProduct(listProducts.payload);
  };
  const getClientList = async () => {
    const clientList = await dispatch(allClients());
    setListClient(clientList.payload);
  };

  useEffect(() => {
    getClientList()
    getProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(reloadingClient === true) {
      getClientList()
      setReloadingClient(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadingClient])

  return (
    <DivComercial>
      <TitleComercial>Comercial</TitleComercial>
      <DivOrgComercial>
        <Order
          infoOrderChange={infoOrderChange.orderChange}
          listProduct={listProduct?.product}
          listClient= {listClient?.client}
          setReloadingClient={setReloadingClient}
        />
      </DivOrgComercial>
    </DivComercial>
  );
}
