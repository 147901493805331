import React, { useEffect, useState } from "react";
import { Close } from "@styled-icons/material";
import {
  DivUpdateClient,
  FormClient,
  DivOrgClient,
  LabelClient,
  LabelClientStreet,
  InputClientStreet,
  InputClientNumber,
  InputClient,
  SubmitFormClient,
  InputMedium,
  DivBtnClose,
  BtnClose,
  SelectOption,
  Options,
  BtnCancel,
  DivBtn,
  DivOrgLoading,
  DivOrgResults,
  InfoResult,
} from "./UpdateClientStyle";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { infoState } from "../../../utils/infoState.mjs";
import {
  updtClient,
  getClient,
} from "../../../store/registers/clients/clients.actions";
import { PatternFormat } from "react-number-format";
import { ClipLoader } from "react-spinners";

export default function UpdateClient(props) {
  const clientDetail = props.dataClientUpdate;
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const [clientErro, setClientErro] = useState([]);

  const [clientInfo, setClientInfo] = useState({});
  const [docClient, setDocClient] = useState();
  const [phoneClient, setPhoneClient] = useState();
  const [houseNumClient, setHouseNumClient] = useState();

  const [loadingUpdateClient, setLoadingUpdateClient] = useState();
  const [disableBtn, setDisableBtn] = useState(false);

  const [dataClient, setDataClient] = useState({
    docClient: clientDetail.docClient || "",
    clientName: clientDetail.clientName || "",
    lastName: clientDetail.lastName || "",
    street: clientDetail.street || "",
    numberPhone: clientDetail.numberPhone || "",
    localNumber: clientDetail.localNumber || "",
    complement: clientDetail.complement || "",
    district: clientDetail.district || "",
    city: clientDetail.city || "",
    state: clientDetail.state || "",
  });

  const clientUpdt = async () => {
    setLoadingUpdateClient(true);
    dataClient.idClient = clientDetail.idClient;
    if (docClient !== undefined) {
      dataClient.docClient = docClient;
    }
    if (phoneClient !== undefined) {
      dataClient.numberPhone = phoneClient;
    }
    if (houseNumClient !== undefined) {
      dataClient.localNumber = houseNumClient;
    }
    const upClient = await dispatch(updtClient(dataClient));
    setClientInfo(upClient.payload);
    setTimeout(() => {
      setLoadingUpdateClient(false);
    }, 500);
    setTimeout(() => {
      props.setLoadingClients(true);
      props.setClientPopUp(false);
    }, 3000);
  };

  const getClientCPF = async (docClient) => {
    const clientCpf = await dispatch(getClient(docClient));
    setClientErro(clientCpf.payload);
  };

  const parseName = (oneName) => {
    const fullName = oneName;

    const formatName = fullName.split(" ");
    for (var i = 0; i < formatName.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName.join(" ");

    return result;
  };

  useEffect(() => {
    if (docClient?.length === 11) {
      getClientCPF(docClient);
    } else {
      setClientErro([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docClient]);

  useEffect(() => {
    if (clientErro?.codeStatus === 200) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [clientErro]);

  return (
    <DivUpdateClient show={props.clientPopUp}>
      <FormClient onSubmit={handleSubmit(clientUpdt)}>
        <DivBtnClose>
          <BtnClose
            type="button"
            onClick={() => {
              props.setClientPopUp(false);
            }}
          >
            <Close />
          </BtnClose>
        </DivBtnClose>
        <DivOrgClient>
          {clientDetail.docClient.length > 11 ? (
            <LabelClient>CNPJ</LabelClient>
          ) : (
            <LabelClient>CPF</LabelClient>
          )}
          {clientDetail.docClient.length > 11 ? (
            <PatternFormat
              value={clientDetail.docClient}
              customInput={InputMedium}
              format="##.###.###/####-##"
              allowEmptyFormatting
              mask="_"
              onValueChange={(values, sourceInfo) => {
                setDocClient(values.value);
              }}
            />
          ) : (
            <PatternFormat
              value={clientDetail.docClient}
              customInput={InputMedium}
              format="###.###.###-##"
              allowEmptyFormatting
              mask="_"
              onValueChange={(values, sourceInfo) => {
                setDocClient(values.value);
              }}
            />
          )}
        </DivOrgClient>
        {clientErro.codeStatus === 200 && (
          <DivOrgResults>
            <InfoResult>Já existe um cliente com esse CPF</InfoResult>
          </DivOrgResults>
        )}
        <DivOrgClient>
          <LabelClient>Nome</LabelClient>
          <InputMedium
            value={parseName(dataClient.clientName)}
            {...register("clientName", {
              onChange: (e) => {
                setDataClient({
                  ...dataClient,
                  clientName: e.target.value,
                });
              },
            })}
          />
        </DivOrgClient>
        <DivOrgClient>
          <LabelClient>Sobrenome</LabelClient>
          <InputMedium
            value={parseName(dataClient.lastName)}
            {...register("lastName", {
              onChange: (e) => {
                setDataClient({
                  ...dataClient,
                  lastName: e.target.value,
                });
              },
            })}
          />
        </DivOrgClient>
        <DivOrgClient>
          <LabelClient>Telefone</LabelClient>
          <PatternFormat
            value={dataClient.numberPhone}
            customInput={InputMedium}
            format="(##) #####-####"
            allowEmptyFormatting
            mask="_"
            onValueChange={(values, sourceInfo) => {
              setPhoneClient(values.value);
            }}
          />
        </DivOrgClient>
        <DivOrgClient>
          <LabelClientStreet>Rua</LabelClientStreet>
          <InputClientStreet
            value={parseName(dataClient.street) || ""}
            {...register("street", {
              onChange: (e) => {
                setDataClient({
                  ...dataClient,
                  street: e.target.value,
                });
              },
            })}
          />
          <PatternFormat
            value={dataClient.localNumber}
            placeholder="Nº"
            customInput={InputClientNumber}
            format="Nº #####"
            onValueChange={(values, sourceInfo) => {
              setHouseNumClient(values.value);
            }}
          />
        </DivOrgClient>
        <DivOrgClient>
          <LabelClient>Bairro</LabelClient>
          <InputMedium
            value={dataClient.district || ""}
            {...register("district", {
              onChange: (e) => {
                setDataClient({
                  ...dataClient,
                  district: e.target.value,
                });
              },
            })}
          />
        </DivOrgClient>
        <DivOrgClient>
          <LabelClient>Cidade</LabelClient>
          <InputMedium
            value={dataClient.city || ""}
            {...register("city", {
              onChange: (e) => {
                setDataClient({
                  ...dataClient,
                  city: e.target.value,
                });
              },
            })}
          />
          <LabelClient>Estado</LabelClient>
          <SelectOption
            value={dataClient.state || ""}
            {...register("state", {
              onChange: (e) => {
                setDataClient({
                  ...dataClient,
                  state: e.target.value,
                });
              },
              required: true,
            })}
          >
            <Options value="">Selecione</Options>
            {infoState.map((infoStates, index) => {
              return (
                <Options key={index} value={infoStates.value}>
                  {infoStates.value}
                </Options>
              );
            })}
          </SelectOption>
        </DivOrgClient>
        <DivOrgClient>
          <LabelClient>Complemento</LabelClient>
          <InputClient
            value={dataClient.complement || ""}
            {...register("complement", {
              onChange: (e) => {
                setDataClient({
                  ...dataClient,
                  complement: e.target.value,
                });
              },
            })}
          />
        </DivOrgClient>
        <DivBtn>
          <BtnCancel type="button" onClick={() => props.setClientPopUp(false)}>
            Cancelar
          </BtnCancel>
          <SubmitFormClient type="submit" disabled={disableBtn}>
            Salvar
          </SubmitFormClient>
        </DivBtn>
        {loadingUpdateClient ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} color={"#000"} />
          </DivOrgLoading>
        ) : (
          (clientInfo.codeStatus === 409 && (
            <DivOrgResults>
              <InfoResult>{clientInfo.message}</InfoResult>
            </DivOrgResults>
          )) ||
          (clientInfo.codeStatus === 404 && (
            <DivOrgResults>
              <InfoResult>{clientInfo.message}</InfoResult>
            </DivOrgResults>
          )) ||
          (clientInfo.codeStatus === 200 && (
            <DivOrgResults>
              <InfoResult>{clientInfo.message}</InfoResult>
            </DivOrgResults>
          ))
        )}
      </FormClient>
    </DivUpdateClient>
  );
}
