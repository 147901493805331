import React, { useState } from "react";
import {
  DivOrgCardDelete,
  DivOrgBtnClose,
  BtnClose,
  DivOrgLabel,
  DivOrgBtn,
  BtnConfirm,
  BtnCancel,
  LabelDelete,
  DivOrgDelete,
  InfoResult,
  DivOrgResults,
  DivOrgLoading,
} from "./DeleteClientSonStyle";
import { Close } from "@styled-icons/material";
import { downClientSon } from "../../../store/registers/clientSons/clientSons.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

export default function DeleteClientSon(props) {
  const clientSonRemove = props.selectClientSon;
  const dispatch = useDispatch();
  const [deleteClientSonInfo, setDeleteClientSonInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const deleteClientSon = async (dataClientSon) => {
    setLoading(true);
    const idClientSon = dataClientSon.idClientSon;
    const deleteClientSon = await dispatch(downClientSon(idClientSon));
    setDeleteClientSonInfo(deleteClientSon.payload);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setTimeout(() => {
      props.setLoadingClientSon(true);
      props.setDelClientSon(false)
    }, 4000);
  };

  return (
    <DivOrgDelete>
      <DivOrgBtnClose>
        <BtnClose onClick={() => props.setDelClientSon(false)}>
          <Close />
        </BtnClose>
      </DivOrgBtnClose>
      <DivOrgCardDelete showDiv={props.delClientSon}>
        <DivOrgLabel>
          <LabelDelete>
            Deseja apagar esse filho ({clientSonRemove.nameSon})
          </LabelDelete>
        </DivOrgLabel>
        <DivOrgBtn>
          <BtnConfirm onClick={() => deleteClientSon(clientSonRemove)}>
            Sim
          </BtnConfirm>
          <BtnCancel onClick={() => props.setDelClientSon(false)}>
            Não
          </BtnCancel>
        </DivOrgBtn>
      </DivOrgCardDelete>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (deleteClientSonInfo.errorStatus && (
          <DivOrgResults>
            <InfoResult>{deleteClientSonInfo.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (deleteClientSonInfo.successStatus && (
          <DivOrgResults>
            <InfoResult>{deleteClientSonInfo.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivOrgDelete>
  );
}
