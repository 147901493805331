import React, { useEffect, useState } from "react";
import {
  DivTableClient,
  DivTableInfo,
  DivClient,
  DivClientInfo,
  DivOrgInfo,
  DivOrgLoading,
  BtnView,
  DivBtnView,
  BtnPayment,
  DivOrgCard,
  DivFilter,
  DivOrgFilter,
  DivOrgInputs,
  FilterLabel,
  CodInput,
  InputDate,
  DivOrgBtn,
  BtnSearch,
  BtnCancel,
} from "./TableInfoClientStyle";
import FormatDatesFront from "../../../utils/formatDateFront.mjs";
import InfoResumeClientDetail from "../../Info/InfoResumeClientDetail/InfoResumeClientDetail";
import { ClipLoader } from "react-spinners";
import {
  Close,
  MonetizationOn,
  Search,
  Visibility,
} from "@styled-icons/material";
import InfoResumeClientPayment from "../../Info/InfoResumeClientPayment/InfoResumeClientPayment";

export default function TableInfoClient(props) {
  const infoOrders = props.detailsClient;

  // Instancia de formatação de datas
  const formatDate = new FormatDatesFront();

  // Estado para visualização de detalhes do pedido
  const [selectDetailView, setSelectDetailView] = useState();
  const [detailView, setDetailView] = useState(false);

  // Estado para visualização de detalhes de pagamento
  const [selectPayView, setSelectPayView] = useState();
  const [paymentView, setPaymentView] = useState(false);

  // Estado para filtros
  const [filterCodOrder, setFilterCodOrder] = useState("");
  const [filterDateStartOrder, setFilterDateStartOrder] = useState("");
  const [filterDateFinishOrder, setFilterDateFinishOrder] = useState("");
  const [filterInfoOrder, setFilterInfoOrder] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const createListOrders = (dataClient) => {
    setLoading(true);
    setShowList(true);

    if (showList) {
      setFilterInfoOrder(dataClient);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const filterOrders = () => {
    const filterList = infoOrders.ordersList
      .filter((order) =>
        filterCodOrder > 0 ? order.idOrder === filterCodOrder : order
      )
      .filter((product) =>
        filterDateStartOrder
          ? formatDate.compareDatesAfter(
              product.dateCreated,
              filterDateStartOrder
            ) >= 0
          : product
      )
      .filter((product) =>
        filterDateFinishOrder
          ? formatDate.compareDatesAfter(
              product.dateCreated,
              filterDateFinishOrder
            ) <= -0
          : product
      );

    setFilterInfoOrder(filterList);
  };

  useEffect(() => {
    createListOrders(infoOrders?.ordersList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoOrders?.ordersList]);

  return (
    <>
      <DivFilter>
        <DivOrgFilter>
          <DivOrgInputs>
            <FilterLabel>Nº do pedido</FilterLabel>
            <CodInput
              value={filterCodOrder}
              onValueChange={(values, sourceInfo) => {
                setFilterCodOrder(parseInt(values.value));
              }}
            />
          </DivOrgInputs>
          <DivOrgInputs>
            <FilterLabel>Data Inicio</FilterLabel>
            <InputDate
              type="date"
              value={filterDateStartOrder}
              onChange={(e) => setFilterDateStartOrder(e.target.value)}
            />
          </DivOrgInputs>
          <DivOrgInputs>
            <FilterLabel>Data Final</FilterLabel>
            <InputDate
              type="date"
              value={filterDateFinishOrder}
              onChange={(e) => setFilterDateFinishOrder(e.target.value)}
            />
          </DivOrgInputs>
          <DivOrgBtn>
            <BtnSearch type="button" onClick={filterOrders}>
              <Search />
            </BtnSearch>
            <BtnCancel
              type="button"
              onClick={() => {
                setFilterCodOrder("");
                setFilterDateStartOrder("");
                setFilterDateFinishOrder("");
                setFilterInfoOrder(infoOrders.ordersList);
              }}
            >
              <Close />
            </BtnCancel>
          </DivOrgBtn>
        </DivOrgFilter>
      </DivFilter>
      <DivTableClient>
        <DivTableInfo>
          {loading ? (
            <DivOrgLoading>
              <ClipLoader speedMultiplier={3} color={"#fff"} />
            </DivOrgLoading>
          ) : (
            filterInfoOrder.map((infoOrders, index) => {
              return (
                // Div de cada Item
                <DivClient key={index}>
                  <DivClientInfo>
                    <DivOrgCard>
                      <DivOrgInfo>
                        Numero do Pedido: <br />
                        {infoOrders.idOrder}
                      </DivOrgInfo>
                      <DivOrgInfo>
                        Pedido realizado em: <br />
                        {infoOrders.dateCreated}
                      </DivOrgInfo>
                      {infoOrders.valueDiscount && (
                        <DivOrgInfo>
                          Desconto do pedido <br />
                          {infoOrders.valueDiscount.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </DivOrgInfo>
                      )}
                      <DivOrgInfo>
                        Total <br />
                        {infoOrders.totalOrder.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </DivOrgInfo>
                      <DivOrgInfo>
                        Status: <br />
                        {infoOrders.statusOrder}
                      </DivOrgInfo>
                    </DivOrgCard>
                    <DivBtnView>
                      <BtnView
                        onClick={() => {
                          setSelectDetailView(infoOrders);
                          setDetailView(!detailView);
                          setPaymentView(false);
                        }}
                      >
                        <Visibility />
                      </BtnView>
                      <BtnPayment
                        onClick={() => {
                          setSelectPayView(infoOrders);
                          setPaymentView(!paymentView);
                          setDetailView(false);
                        }}
                      >
                        <MonetizationOn />
                      </BtnPayment>
                    </DivBtnView>
                  </DivClientInfo>
                  {detailView &&
                    infoOrders.idOrder === selectDetailView.idOrder && (
                      <InfoResumeClientDetail
                        selectDetailView={selectDetailView}
                        detailView={detailView}
                        setDetailView={setDetailView}
                      />
                    )}
                  {paymentView &&
                    infoOrders.idOrder === selectPayView.idOrder && (
                      <InfoResumeClientPayment
                        selectPayView={selectPayView}
                        paymentView={paymentView}
                        setPaymentView={setPaymentView}
                      />
                    )}
                </DivClient>
              );
            })
          )}
        </DivTableInfo>
      </DivTableClient>
    </>
  );
}
