import React, { useState } from "react";
import {
  BtnCancel,
  BtnSearch,
  DivFilter,
  DivFirstScreen,
  DivOrgBtn,
  DivOrgCompany,
  DivOrgDates,
  DivOrgFilter,
  DivOrgScreen,
  DivOrgTitle,
  DivResumeExpense,
  DivResumeFin,
  DivResumeProfit,
  InfoFilter,
  SelectDay,
  TitleCompany,
} from "./ResumeInvoiceStyle.js";
import InvoiceSells from "../../components/Resume/Invoice/InvoiceSells/InvoiceSells.jsx";
import InvoiceMoney from "../../components/Resume/Invoice/InvoiceMoney/InvoiceMoney.jsx";
import InvoiceGraphics from "../../components/Graphs/InvoiceGraphics/InvoiceGraphics.jsx";
import { infoInvoice } from "../../store/infoCompany/invoice/invoice.actions.js";
import { useDispatch } from "react-redux";
import { Close, Search } from "@styled-icons/material";

export default function ResumeInvoice() {
  const dispatch = useDispatch();

  const [dateStartSells, setDateStartSells] = useState("");
  const [dateFinishSells, setDateFinishSells] = useState("");
  const [statusData, setStatusData] = useState(false);
  const [loading, setLoading] = useState();
  const [dataInvoiceSells, setDataInvoiceSells] = useState([]);
  const [dataInvoiceGraphs, setDataInvoiceGraphs] = useState([]);
  const [dataInvoiceMoviments, setdataInvoiceMoviments] = useState([]);

  const getInfoInvoice = async (infoDate) => {
    setLoading(true);
    const organizeDate = {
      dateStart: infoDate[0],
      dateFinish: infoDate[1],
    };

    if (
      organizeDate.dateStart.length > 0 ||
      organizeDate.dateFinish.length > 0
    ) {
      const invoiceData = await dispatch(infoInvoice(organizeDate));

      setDataInvoiceSells(invoiceData.payload);
      setdataInvoiceMoviments(invoiceData.payload);
      setDataInvoiceGraphs(invoiceData.payload);
      setStatusData(false)
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  return (
    <DivOrgCompany>
      <DivOrgTitle>
        <TitleCompany>Resumo do Faturamento</TitleCompany>
      </DivOrgTitle>
      <DivOrgScreen>
        <DivOrgDates>
          <DivFilter>
            <DivOrgFilter>
              <InfoFilter>Inicio</InfoFilter>
              <SelectDay
                type="date"
                value={dateStartSells}
                onChange={(e) => setDateStartSells(e.target.value)}
              />
            </DivOrgFilter>
            <DivOrgFilter>
              <InfoFilter>Fim</InfoFilter>
              <SelectDay
                type="date"
                value={dateFinishSells}
                onChange={(e) => setDateFinishSells(e.target.value)}
              />
            </DivOrgFilter>
            <DivOrgBtn>
              <BtnSearch
                type="button"
                onClick={() =>
                  getInfoInvoice([dateStartSells, dateFinishSells])
                }
              >
                <Search />
              </BtnSearch>
              <BtnCancel
                type="button"
                onClick={() => {
                  setDateStartSells("");
                  setDateFinishSells("");
                  setDataInvoiceSells([]);
                  setdataInvoiceMoviments([]);
                  setStatusData(true)
                }}
              >
                <Close />
              </BtnCancel>
            </DivOrgBtn>
          </DivFilter>
        </DivOrgDates>
        <DivFirstScreen>
          <DivResumeFin>
            <InvoiceSells
              dataInvoiceSells={dataInvoiceSells?.infoOrders}
              loading={loading}
            />
          </DivResumeFin>
          <DivResumeExpense>
            <InvoiceMoney
              dataInvoiceMoviments={dataInvoiceMoviments}
              loading={loading}
            />
          </DivResumeExpense>
        </DivFirstScreen>
        <DivResumeProfit>
          <InvoiceGraphics
            invoiceGraph={dataInvoiceGraphs.invoiceGraph}
            
            statusData={statusData}
          />
        </DivResumeProfit>
      </DivOrgScreen>
    </DivOrgCompany>
  );
}
