import React, { useState } from "react";
import {
  BtnCancel,
  BtnSearch,
  DivFilter,
  DivFirstScreen,
  DivOrgBtn,
  DivOrgCompany,
  DivOrgDates,
  DivOrgFilter,
  DivOrgScreen,
  DivOrgTitle,
  DivResumeProfit,
  InfoFilter,
  SelectDay,
  TitleCompany,
} from "./ResumeProfitsStyle";
import ProfitInfo from "../../components/Resume/Profits/ProfitInfo/ProfitInfo";
import { infoProfit } from "../../store/infoCompany/profit/profit.actions";
import ProfitGraphics from "../../components/Graphs/ProfitGraphics/ProfitGraphics";
import { useDispatch } from "react-redux";
import { Close, Search } from "@styled-icons/material";

export default function ResumeProfits() {
  const dispatch = useDispatch();

  const [dateStartStock, setDateStartStock] = useState("");
  const [dateFinishStock, setDateFinishStock] = useState("");
  const [loading, setLoading] = useState(false);
  const [statusData, setStatusData] = useState(false);

  const [dataInfoProfit, setDataInfoProfit] = useState([]);
  const [dataProfitGraph, setDataProfitGraph] = useState([]);
  const [dataProfitGraphDay, setDataProfitGraphDay] = useState([]);

  const getInfoProfit = async (infoDateStart, infoDateFinish) => {
    if (infoDateStart.length > 0 || infoDateFinish.length > 0) {
      setLoading(true);
      const organizeDate = {
        dateStart: infoDateStart,
        dateFinish: infoDateFinish,
      };
      const profitData = await dispatch(infoProfit(organizeDate));

      setDataInfoProfit(profitData.payload);
      setDataProfitGraph(profitData.payload.profitGraph);
      setDataProfitGraphDay(profitData.payload.profitGraphByDay);
      setStatusData(false);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  return (
    <DivOrgCompany>
      <DivOrgTitle>
        <TitleCompany>Lucro da Empresa</TitleCompany>
      </DivOrgTitle>
      <DivOrgScreen>
        <DivOrgDates>
          <DivFilter>
            <DivOrgFilter>
              <InfoFilter>Inicio</InfoFilter>
              <SelectDay
                type="date"
                value={dateStartStock}
                onChange={(e) => setDateStartStock(e.target.value)}
              />
            </DivOrgFilter>
            <DivOrgFilter>
              <InfoFilter>Fim</InfoFilter>
              <SelectDay
                type="date"
                value={dateFinishStock}
                onChange={(e) => setDateFinishStock(e.target.value)}
              />
            </DivOrgFilter>
            <DivOrgBtn>
              <BtnSearch
                type="button"
                onClick={() => getInfoProfit(dateStartStock, dateFinishStock)}
              >
                <Search />
              </BtnSearch>
              <BtnCancel
                type="button"
                onClick={() => {
                  setDateStartStock("");
                  setDateFinishStock("");
                  setDataInfoProfit([]);
                  setStatusData(true);
                }}
              >
                <Close />
              </BtnCancel>
            </DivOrgBtn>
          </DivFilter>
        </DivOrgDates>
        <DivFirstScreen>
          <ProfitInfo
            dataInfoProfit={dataInfoProfit?.infoProfit}
            loading={loading}
          />
        </DivFirstScreen>
        <DivResumeProfit>
          <ProfitGraphics
            profitGraph={dataProfitGraph?.profitGraph}
            profitGraphByDay={dataProfitGraphDay?.profitGraph}
            statusData={statusData}
          />
        </DivResumeProfit>
      </DivOrgScreen>
    </DivOrgCompany>
  );
}
