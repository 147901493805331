import React, { useEffect, useState } from "react";
import {
  Close,
  Edit,
  PersonRemove,
  Search,
  Visibility,
} from "@styled-icons/material";
import {
  BtnEdit,
  BtnRemove,
  TitleProvider,
  BtnSearch,
  BtnView,
  DivBtnEdit,
  DivBtnSearch,
  DivIdProvider,
  DivProvider,
  DivProviderInfo,
  DivSearch,
  DivSearchProvider,
  DivTableSearch,
  DivInfo,
  NameInput,
  NameLabel,
  SpanName,
  DivOrgLoading,
  DivOrgCard,
  FormatCnpj,
  DivBtnFilter,
  FormatCnpjText,
  BtnCancel,
  IdInfo,
} from "./SearchProviderStyle";
import UpdateProvider from "../../Update/UpdateProvider/UpdateProvider";
import InfoProvider from "../../Info/InfoProvider/InfoProvider";
import DeleteProvider from "../../DeleteComponent/DeleteProvider/DeleteProvider";

import { ClipLoader } from "react-spinners";

export default function SearchProvider(props) {
  const providersInfo = props.providersInfo;

  const [showList, setShowList] = useState(false);
  // const [provider, setProvider] = useState([]);
  const [providerPopUp, setProviderPopUp] = useState(false);
  const [delProviderOption, setDelProviderOption] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedProviderView, setSelectedProviderView] = useState([]);
  const [filterCnpj, setFilterCnpj] = useState();
  const [filterNameProvider, setFilterNameProvider] = useState("");
  const [filterInfoProvider, setFilterInfoProvider] = useState([]);

  const [providerView, setProviderView] = useState(false);
  const [loading, setLoading] = useState();
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [dataProviderUpdate, setDataProviderUpdate] = useState([]);

  const createListProviders = (dataProvider) => {
    setLoading(true);
    setShowList(true);
    if (showList) {
      setFilterInfoProvider(dataProvider);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const parseName = (oneName) => {
    if (oneName) {
      const firstName = oneName || "";

      var fullName = firstName.concat("");

      const formatName = fullName.split(" ");
      for (var i = 0; i < formatName.length; i++) {
        formatName[i] =
          formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
      }
      let result = formatName?.join(" ");

      return result;
    }
  };

  const filterProvider = () => {
    const filterList = providersInfo
      .filter((provider) =>
        filterCnpj.length > 0 ? provider.cnpj.startsWith(filterCnpj) : provider
      )
      .filter((provider) =>
        filterNameProvider.length > 0
          ? provider.nameProvider.includes(filterNameProvider.toLocaleLowerCase())
          : provider
      );

    setFilterInfoProvider(filterList);
  };

  useEffect(() => {
    createListProviders(providersInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providersInfo]);

  useEffect(() => {
    setLoading(true);
    if (loadingProviders) {
      props.getListProviders();
    }
    setTimeout(() => {
      setLoadingProviders(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProviders]);

  

  return (
    <DivSearchProvider>
      <DivSearch>
        <TitleProvider>Consulta Fornecedor</TitleProvider>
        <DivBtnFilter show={props.disableFilter}>
          <NameLabel>CNPJ</NameLabel>
          <FormatCnpj
            value={filterCnpj}
            format="##.###.###/####-##"
            allowEmptyFormatting
            mask="_"
            onValueChange={(values, sourceInfo) => {
              setFilterCnpj(values.value);
            }}
          />
          <NameLabel>Nome</NameLabel>
          <NameInput
            value={filterNameProvider}
            onChange={(e) => setFilterNameProvider(e.target.value)}
          />
          <DivBtnSearch>
            <BtnSearch type="button" onClick={filterProvider}>
              <Search />
            </BtnSearch>
            <BtnCancel
              type="button"
              onClick={() => {
                setFilterCnpj("");
                setFilterNameProvider("");
                setFilterInfoProvider(providersInfo);
              }}
            >
              <Close />
            </BtnCancel>
          </DivBtnSearch>
        </DivBtnFilter>
      </DivSearch>
      <DivTableSearch>
        {loading ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} color={"#FFF"} />
          </DivOrgLoading>
        ) : (
          filterInfoProvider.map((infoProvider, id) => {
            return (
              <DivProvider key={id}>
                <DivOrgCard>
                  <DivInfo>
                    <DivIdProvider>
                      <IdInfo>{infoProvider.idProvider}</IdInfo>
                    </DivIdProvider>
                    <DivProviderInfo>
                      <SpanName>
                        {parseName(infoProvider.nameProvider)}
                      </SpanName>
                      <FormatCnpjText
                        displayType="text"
                        value={infoProvider.cnpj}
                        format="##.###.###/####-##"
                        allowEmptyFormatting
                        mask="_"
                      />
                    </DivProviderInfo>
                  </DivInfo>
                  <DivBtnEdit>
                    <BtnEdit
                      onClick={() => {
                        setProviderPopUp(!providerPopUp);
                        setDataProviderUpdate(infoProvider);
                      }}
                    >
                      <Edit />
                    </BtnEdit>
                    {providerPopUp &&
                      infoProvider.idProvider ===
                        dataProviderUpdate.idProvider && (
                        <UpdateProvider
                          setLoadingProviders={setLoadingProviders}
                          dataProviderUpdate={dataProviderUpdate}
                          providerPopUp={providerPopUp}
                          setProviderPopUp={setProviderPopUp}
                        />
                      )}

                    <BtnView
                      onClick={() => {
                        setProviderView(!providerView);
                        setSelectedProviderView(infoProvider);
                        setDelProviderOption(false);
                      }}
                    >
                      <Visibility />
                    </BtnView>

                    <BtnRemove
                      onClick={() => {
                        setDelProviderOption(!delProviderOption);
                        setSelectedProvider(infoProvider);
                        setProviderView(false);
                      }}
                    >
                      <PersonRemove />
                    </BtnRemove>
                  </DivBtnEdit>
                </DivOrgCard>
                {providerView &&
                  infoProvider.idProvider ===
                    selectedProviderView.idProvider && (
                    <InfoProvider
                      selectedProviderView={selectedProviderView}
                      providerView={providerView}
                      setProviderView={setProviderView}
                    />
                  )}
                {delProviderOption &&
                  infoProvider.idProvider === selectedProvider.idProvider && (
                    <DeleteProvider
                      setLoadingProviders={setLoadingProviders}
                      selectedProvider={selectedProvider}
                      delProviderOption={delProviderOption}
                      setDelProviderOption={setDelProviderOption}
                    />
                  )}
              </DivProvider>
            );
          })
        )}
      </DivTableSearch>
    </DivSearchProvider>
  );
}
