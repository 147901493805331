import "./app.css";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { useDispatch } from "react-redux";
import PrivateRoute from "./api/routes/PrivateRouter/PrivateRouter";

import Home from "./pages/Home/Home";
import Commercial from "./pages/Commercial/Commercial";
import Welcome from "./pages/Welcome/Welcome";
import ResumeFinancial from "./pages/ResumeFinancial/ResumeFinancial";
import FinancialEntryOrders from "./pages/FinancialEntryOrders/FinancialEntryOrders";
import Cashier from "./pages/Cashier/Cashier";
import Stock from "./pages/Stock/Stock";
import ProviderRegister from "./pages/ProviderRegister/ProviderRegister";
import ClienteRegister from "./pages/ClientRegister/ClientRegister";
import ProductRegister from "./pages/ProductRegister/ProductRegister";
import UserRegister from "./pages/UserRegister/UserRegister";
import BrandRegister from "./pages/BrandRegister/BrandRegister";
import FinishOrder from "./pages/FinishOrder/FinishOrder";
import StockItem from "./pages/StockItem/StockItem";
import ExpenseRegister from "./pages/Expense/ExpenseRegister";
import NoPermission from "./pages/NoPermission/NoPermission";
import UserAccount from "./pages/UserAccount/UserAccount";
// import { AuthProvider } from "./services/routes/authenticate/auth";

import StockOut from "./pages/StockOut/StockOut";
// import StockDevolution from "./pages/StockDevolution/StockDevolution"
import ResumeProvider from "./pages/ResumeProvider/ResumeProvider";
import ResumeClients from "./pages/ResumeClients/ResumeClients";
import HistoricStock from "./pages/HistoricStock/HistoricStock";

import { checkUserStatus } from "./store/auth/auth.actions";
import React, { useEffect } from "react";
import DeliveryRegister from "./pages/DeliveryRegister/DeliveryRegister";
import CategoryRegister from "./pages/CategoryRegister/CategoryRegister";
import { persistor } from "./index";

import ResumeInvoice from "./pages/ResumeInvoice/ResumeInvoice";
import ResumeExpense from "./pages/ResumeExpense/ResumeExpense";
import ResumeProfits from "./pages/ResumeProfits/ResumeProfits";
import ResumeStock from "./pages/ResumeStock/ResumeStock";
import PriceTag from "./pages/PriceTag/PriceTag";
import DetailProduct from "./pages/DetailProduct/DetailProduct";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function isLoggedIn() {
      await dispatch(checkUserStatus());
    }
    isLoggedIn();
  }, [dispatch]);

  useEffect(() => {
    // Define o título da página dinamicamente
    document.title = process.env.REACT_APP_NAME_EMPRESA || "Título Padrão";
  }, []);

  return (
    <Router>
      <PersistGate loading={null} persistor={persistor}>
        <Routes>
          {/* Rotas Publicas*/}
          <Route path="/" element={<Welcome />} />
          <Route path="/not-permission" element={<NoPermission />} />

          {/* Rotas Privadas */}
          <Route
            path="/home"
            element={
              <PrivateRoute requiredPermissions={[5]}>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/comercial"
            element={
              <PrivateRoute requiredPermissions={[1, 5]}>
                <Commercial />
              </PrivateRoute>
            }
          />
          <Route
            path="/comercial/end-ord"
            element={
              <PrivateRoute requiredPermissions={[1, 5]}>
                <FinishOrder />
              </PrivateRoute>
            }
          />
          <Route
            path="/financial/"
            element={
              <PrivateRoute requiredPermissions={[2, 5]}>
                <ResumeFinancial />
              </PrivateRoute>
            }
          />
          <Route
            path="/financial/financial-entry-order"
            element={
              <PrivateRoute requiredPermissions={[2, 5]}>
                <FinancialEntryOrders />
              </PrivateRoute>
            }
          />
          <Route
            path="/financial/resume-clients"
            element={
              <PrivateRoute requiredPermissions={[2, 5]}>
                <ResumeClients />
              </PrivateRoute>
            }
          />
          <Route
            path="/financial/financial-entry-order/resume-provider"
            element={
              <PrivateRoute requiredPermissions={[2, 5]}>
                <ResumeProvider />
              </PrivateRoute>
            }
          />
          <Route
            path="/financial/cashier"
            element={
              <PrivateRoute requiredPermissions={[2, 5, 6, 7]}>
                <Cashier />
              </PrivateRoute>
            }
          />
          <Route
            path="/financial/debits"
            element={
              <PrivateRoute requiredPermissions={[2, 5, 7]}>
                <ExpenseRegister />
              </PrivateRoute>
            }
          />
          <Route
            path="/stock"
            element={
              <PrivateRoute requiredPermissions={[3, 5]}>
                <Stock />
              </PrivateRoute>
            }
          />
          <Route
            path="/stock/historic-stock"
            element={
              <PrivateRoute requiredPermissions={[8, 5]}>
                <HistoricStock />
              </PrivateRoute>
            }
          />
          <Route
            path="/stock/stock-item"
            element={
              <PrivateRoute requiredPermissions={[8, 5]}>
                <StockItem />
              </PrivateRoute>
            }
          />
          <Route
            path="/stock/stock-out"
            element={
              <PrivateRoute requiredPermissions={[8, 5]}>
                <StockOut />
              </PrivateRoute>
            }
          />
          <Route
            path="/stock/etiquetas"
            element={
              <PrivateRoute requiredPermissions={[8, 5]}>
                <PriceTag />
              </PrivateRoute>
            }
          />
          <Route
            path="/stock/details"
            element={
              <PrivateRoute requiredPermissions={[8, 5]}>
                <DetailProduct />
              </PrivateRoute>
            }
          />

          {/* <Route 
          path="/stock/stock-devolution"
          element={
            <PrivateRoute>
              <StockDevolution />
            </PrivateRoute>
          }
        /> */}
          <Route
            path="/new-cli"
            element={
              <PrivateRoute requiredPermissions={[4, 5]}>
                <ClienteRegister />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-product"
            element={
              <PrivateRoute requiredPermissions={[9, 5]}>
                <ProductRegister />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-user"
            element={
              <PrivateRoute requiredPermissions={[5, 9]}>
                <UserRegister />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-brand"
            element={
              <PrivateRoute requiredPermissions={[11, 5]}>
                <BrandRegister />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-delivery"
            element={
              <PrivateRoute requiredPermissions={[12, 5]}>
                <DeliveryRegister />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-category"
            element={
              <PrivateRoute requiredPermissions={[13, 5]}>
                <CategoryRegister />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-provider"
            element={
              <PrivateRoute requiredPermissions={[10, 5]}>
                <ProviderRegister />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume/invoice"
            element={
              <PrivateRoute requiredPermissions={[5]}>
                <ResumeInvoice />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume/expense"
            element={
              <PrivateRoute requiredPermissions={[5]}>
                <ResumeExpense />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume/profits"
            element={
              <PrivateRoute requiredPermissions={[5]}>
                <ResumeProfits />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume/stock"
            element={
              <PrivateRoute requiredPermissions={[5]}>
                <ResumeStock />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute requiredPermissions={[5]}>
                <UserRegister />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-account"
            element={
              <PrivateRoute
                requiredPermissions={[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                ]}
              >
                <UserAccount />
              </PrivateRoute>
            }
          />
        </Routes>
      </PersistGate>
    </Router>
  );
}

export default App;
