import { Close } from "@styled-icons/material";
import React, { useEffect, useState } from "react";
import {
  DivUpdateProduct,
  FormProduct,
  DivFormProduct,
  DivOrgProduct,
  LabelProduct,
  InputProduct,
  InputProductName,
  DivBtnProduct,
  BtnRemoveProduct,
  SubmitProduct,
  DivBtnClose,
  BtnClose,
  TitleUpdate,
  DivOrgTitle,
  DivOrgResults,
  InfoResult,
  DivOrgLoading,
} from "./UpdateProductStyle";

import { useForm } from "react-hook-form";
import {
  updtProduct,
  getProduct,
} from "../../../store/registers/products/products.actions";
import { getBrandInfo } from "../../../store/registers/brand/brand.actions";
import { useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import { NumericFormat, PatternFormat } from "react-number-format";
import { ClipLoader } from "react-spinners";

export default function UpdateProduct(props) {
  const productDetail = props.dataProductsUpdate;
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      priceSell: "",
    },
  });

  const [nameBrand, setNameBrand] = useState();
  const [brandInfo, setBrandInfo] = useState([]);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [nameBrandSearch] = useDebounce(nameBrand, 500);

  const [productInfo, setProductInfo] = useState({});
  const [productDataInfo, setProductDataInfo] = useState({});
  const [priceBuy, setPriceBuy] = useState();
  const [priceSell, setPriceSell] = useState();
  const [percentSell] = useState();
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [codProduct, setCodProduct] = useState();
  const [buttonHide, setButtonHide] = useState(false);
  const [productSearch] = useDebounce(codProduct, 500);
  const [loadingUpdateProduct, setLoadingUpdateProduct] = useState();

  const [dataProduct, setDataProduct] = useState({
    codProd: productDetail.codProd,
    nameProduct: productDetail.nameProduct,
    priceBuy: productDetail.priceBuy,
    percentSell: productDetail.percentSell,
    priceSell: productDetail.priceSell,
    idBrand: productDetail.idBrand,
    nameBrand: productDetail.nameBrand,
  });

  const getBrand = async (dataBrand) => {
    setLoadingBrand(true);
    const nameBrandInfo = await dispatch(getBrandInfo(dataBrand));
    setBrandInfo(nameBrandInfo.payload);
    setLoadingBrand(false);
  };

  const getProductCod = async (dataProduct) => {
    setLoadingProduct(true);
    const nameProductInfo = await dispatch(getProduct(dataProduct));
    setProductDataInfo(nameProductInfo.payload);
    setLoadingProduct(false);
  };

  const productUpdt = async () => {
    setLoadingUpdateProduct(true);
    dataProduct.idProduct = productDetail.idProduct;
    if (priceBuy !== undefined) {
      dataProduct.priceBuy = Number(priceBuy);
    }
    if (priceBuy === 0) {
      dataProduct.priceBuy = null;
    }
    if (percentSell !== undefined) {
      dataProduct.percentSell = percentSell;
    }
    if (percentSell === 0) {
      dataProduct.percentSell = null;
    }
    if (priceSell !== undefined) {
      dataProduct.priceSell = Number(priceSell);
    }
    if (brandInfo?.brand?.idBrand !== undefined) {
      dataProduct.idBrand = brandInfo?.brand?.idBrand;
    } else {
      dataProduct.idBrand = productDetail.idBrand;
    }

    const upProduct = await dispatch(updtProduct(dataProduct));
    setProductInfo(upProduct.payload);
    setTimeout(() => {
      setLoadingUpdateProduct(false);
    }, 1000);
    setTimeout(() => {
      props.setLoadingProducts(true);
      props.setProductPopUp(false);
    }, 3000);
  };

  const parseName = (oneName) => {
    const fullName = oneName;

    const formatName = fullName.split(" ");
    for (var i = 0; i < formatName.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName.join(" ");

    return result;
  };

  useEffect(() => {
    if (nameBrandSearch?.length === 0) {
      setBrandInfo(nameBrandSearch);
    }

    if (nameBrandSearch) {
      getBrand(nameBrandSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameBrandSearch]);

  useEffect(() => {
    if (dataProduct.codProd === productDetail.codProd) {
    } else {
      if (productSearch) {
        getProductCod(productSearch);
        if (productDataInfo.successStatus === true) {
          setButtonHide(true);
        } else {
          setButtonHide(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSearch, productDataInfo.successStatus]);

  // useEffect(() => {
  //   let priceBuyProduct = dataProduct.priceBuy;
  //   let percentProductSell = dataProduct.percentSell;
  //   if (priceBuy !== undefined) {
  //     priceBuyProduct = priceBuy;
  //   }
  //   if (percentSell !== undefined) {
  //     percentProductSell = percentSell;
  //   }
  //   let priceProduct =
  //     parseFloat(priceBuyProduct) +
  //     parseFloat((priceBuyProduct * percentProductSell) / 100);

  //   setDataProduct({ ...dataProduct, priceSell: priceProduct });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [percentSell, priceBuy, dataProduct.priceBuy, dataProduct.percentSell]);

  return (
    <DivUpdateProduct show={props.productPopUp}>
      <FormProduct onSubmit={handleSubmit(productUpdt)}>
        <DivBtnClose>
          <DivOrgTitle>
            <TitleUpdate>Atualizar Produto</TitleUpdate>
          </DivOrgTitle>
          <BtnClose onClick={() => props.setProductPopUp(false)}>
            <Close />
          </BtnClose>
        </DivBtnClose>
        <DivFormProduct>
          <DivOrgProduct>
            <LabelProduct>Codigo do Produto</LabelProduct>
            <InputProduct
              value={dataProduct.codProd}
              {...register("codProd", {
                onChange: (e) => {
                  setDataProduct({
                    ...dataProduct,
                    codProd: e.target.value,
                  });
                  setCodProduct(e.target.value);
                },
              })}
            />
          </DivOrgProduct>
          {loadingProduct ? (
            <ClipLoader speedMultiplier={2} />
          ) : (
            productDataInfo.successStatus && (
              <DivOrgResults>
                <InfoResult>Já existe Produto com esse código</InfoResult>
              </DivOrgResults>
            )
          )}

          <DivOrgProduct>
            <LabelProduct>Nome</LabelProduct>
            <InputProductName
              value={parseName(dataProduct.nameProduct)}
              {...register("nameProduct", {
                onChange: (e) => {
                  setDataProduct({
                    ...dataProduct,
                    nameProduct: e.target.value,
                  });
                },
              })}
            />
          </DivOrgProduct>

          <DivOrgProduct>
            <LabelProduct>Preço de Compra</LabelProduct>
            <NumericFormat
              placeholder=""
              customInput={InputProduct}
              value={dataProduct.priceBuy}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              prefix={"R$ "}
              onValueChange={(values, sourceInfo) => {
                setPriceBuy(Number(values.value));
              }}
            />
            {/* <InputProduct {...register("priceBuy")} /> */}
          </DivOrgProduct>
          <DivOrgProduct>
            <LabelProduct>Porcentagem de Venda (%)</LabelProduct>
            <PatternFormat
              customInput={InputProduct}
              format="###%"
              value={dataProduct.percentSell}
              allowEmptyFormatting
              mask="_"
              placeholder="%"
             displayType="text"
            />
            {/* <InputProduct {...register("percentSell")} /> */}
          </DivOrgProduct>
          <DivOrgProduct>
            <LabelProduct>Preço de Venda</LabelProduct>
            <NumericFormat
              value={dataProduct.priceSell}
              customInput={InputProduct}
              placeholder="R$"
              allowEmptyFormatting
              mask="_"
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale
              decimalScale={2}
              prefix={"R$"}
              onValueChange={(values, sourceInfo) => {
                setPriceSell(values.value);
              }}
            />
          </DivOrgProduct>
          <DivOrgProduct>
            <LabelProduct>Marca</LabelProduct>
            {/* Buscar uma marca */}
            <InputProduct
              value={dataProduct.nameBrand}
              {...register("idBrand")}
              onChange={(e) => {
                setNameBrand(e.target.value);
                setDataProduct({
                  ...dataProduct,
                  nameBrand: e.target.value,
                });
              }}
            />
          </DivOrgProduct>
          {loadingBrand ? (
            <DivOrgLoading>
              <ClipLoader speedMultiplier={3} color={"#FFF"} />
            </DivOrgLoading>
          ) : (
            (brandInfo.errorStatus && (
              <DivOrgResults>
                <InfoResult>{brandInfo.message}</InfoResult>
              </DivOrgResults>
            )) ||
            (brandInfo.successStatus && (
              <DivOrgResults>
                <InfoResult>{brandInfo.message}</InfoResult>
              </DivOrgResults>
            ))
          )}
        </DivFormProduct>
        <DivBtnProduct>
          <BtnRemoveProduct
            type="button"
            onClick={() => props.setProductPopUp(false)}
          >
            Cancelar
          </BtnRemoveProduct>
          <SubmitProduct type="submit" disabled={buttonHide}>
            Salvar
          </SubmitProduct>
        </DivBtnProduct>
        {loadingUpdateProduct ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} />
          </DivOrgLoading>
        ) : (
          (productInfo.errorStatus && (
            <DivOrgResults>
              <InfoResult>{productInfo.message}</InfoResult>
            </DivOrgResults>
          )) ||
          (productInfo.successStatus && (
            <DivOrgResults>
              <InfoResult>{productInfo.message}</InfoResult>
            </DivOrgResults>
          ))
        )}
      </FormProduct>
    </DivUpdateProduct>
  );
}
