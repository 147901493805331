import React, { useEffect, useState } from "react";
import {
  DivUpdateClientSon,
  FormClientSon,
  DivOrgClientSon,
  LabelClientSon,
  InputClientSon,
  SubmitClientSon,
  DivBtnClientSon,
  BtnRemoveClientSon,
  DivBtnClose,
  BtnClose,
  DivOrgLoading,
  DivOrgResults,
  InfoResult,
  DivOrgTitle,
  TitleClientSon,
} from "./UpdateClientSonStyle";
import { useForm } from "react-hook-form";
import { Close } from "@styled-icons/material";

import {
  getClientSon,
  updtClientSon,
} from "../../../store/registers/clientSons/clientSons.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useDebounce } from "use-debounce";
import FormatDatesFront from "../../../utils/formatDateFront.mjs";

export default function UpdateClientSon(props) {
  const clientSonDetail = props.dataClientSon;

  const dispatch = useDispatch();
  const FormatDate = new FormatDatesFront();
  const { register, handleSubmit } = useForm();
  const [clientSonInfo, setClientSonInfo] = useState([]);
  const [loadingUpdateClientSon, setLoadingUpdateClientSon] = useState();

  const [nameClientSon, setNameClientSon] = useState("");
  const [clientSonError, setClientSonError] = useState([]);
  const [clientSonSearch] = useDebounce(nameClientSon, 500);
  const [disableBtn, setDisableBtn] = useState(false);

  const [dataClientSon, setDataClientSon] = useState({
    idClientSon: clientSonDetail.idClientSon || "",
    nameSon: clientSonDetail.nameSon || "",
    birthdaySon: FormatDate.formatDateNoHour(clientSonDetail.birthdaySon) || ""
  });

  const clientSonUptd = async () => {
    setLoadingUpdateClientSon(true);
    // if (valueClientSon !== undefined) {
    //   dataClientSon.valueClientSon = valueClientSon;
    // }
    const upClientSon = await dispatch(updtClientSon(dataClientSon));
    setClientSonInfo(upClientSon.payload);
    setTimeout(() => {
      setLoadingUpdateClientSon(false);
    });
    if (upClientSon.payload.successStatus === true) {
      setTimeout(() => {
        props.setLoadingClientSon(true);
        props.setClientSonPop(false);
      }, 3000);
    }
  };

  const verifyClientSon = async (clientSon) => {
    const infoClientSon = {
      nameClientSon: clientSon.toLowerCase(),
      idCategory: clientSonDetail.idCategory,
    };
    const clientSonExists = await dispatch(
      getClientSon(infoClientSon)
    );
    setClientSonError(clientSonExists.payload);
    
  };

  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } else {
      fullName = firstName;
    }
    const formatName = fullName?.split(" ");
    for (var i = 0; i < formatName?.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName?.join(" ");

    return result;
  };

  useEffect(() => {
    if (clientSonSearch.length === 0) {
      setClientSonError(clientSonSearch);
    }

    if (clientSonSearch) {
      verifyClientSon(clientSonSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSonSearch]);

  useEffect(() => {
    if (clientSonError.codeStatus === 200) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [clientSonError]);

  return (
    <DivUpdateClientSon show={props.clientSonPop}>
      <DivBtnClose>
        <BtnClose onClick={() => props.setClientSonPop(false)}>
          <Close />
        </BtnClose>
      </DivBtnClose>
      <FormClientSon onSubmit={handleSubmit(clientSonUptd)}>
        <DivOrgTitle>
          <TitleClientSon>
            Atualizar dados do {parseName(clientSonDetail.nameSon)}
          </TitleClientSon>
        </DivOrgTitle>
        <DivOrgClientSon>
          <LabelClientSon>Nome</LabelClientSon>
          <InputClientSon
            value={dataClientSon.nameSon}
            {...register("sonName", {
              onChange: (e) => {
                setDataClientSon({
                  ...dataClientSon,
                  nameSon: e.target.value,
                });
                setNameClientSon(e.target.value)
              },
            })}
          />
        </DivOrgClientSon>
        <DivOrgClientSon>
          <LabelClientSon>Data de nascimento</LabelClientSon>
          <InputClientSon
          type="date"
            value={dataClientSon.birthdaySon}
            {...register("birthdaySon", {
              onChange: (e) => {
                setDataClientSon({
                  ...dataClientSon,
                  birthdaySon: e.target.value,
                });
              },
            })}
          />
        </DivOrgClientSon>
        {clientSonError.codeStatus === 200 && (
          <DivOrgResults>
            <InfoResult>
              Esse filho já foi cadastrado com esse nome
            </InfoResult>
          </DivOrgResults>
        )}
        <DivBtnClientSon>
          <BtnRemoveClientSon
            type="button"
            onClick={() => {
              props.setClientSonPop(false);
            }}
          >
            Cancelar
          </BtnRemoveClientSon>
          <SubmitClientSon type="submit" disabled={disableBtn}>
            Salvar
          </SubmitClientSon>
        </DivBtnClientSon>
        {loadingUpdateClientSon ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} />
          </DivOrgLoading>
        ) : (
          (clientSonInfo.errorStatus && (
            <DivOrgResults>
              <InfoResult>{clientSonInfo.message}</InfoResult>
            </DivOrgResults>
          )) ||
          (clientSonInfo.successStatus && (
            <DivOrgResults>
              <InfoResult>{clientSonInfo.message}</InfoResult>
            </DivOrgResults>
          ))
        )}
      </FormClientSon>
    </DivUpdateClientSon>
  );
}
