import React, { useEffect, useState } from "react";
import {
  DivNewSon,
  FormSon,
  DivOrgSon,
  LabelSon,
  InputSon,
  SubmitSon,
  DivBtnSon,
  BtnRemoveSon,
  DivBtnClose,
  BtnClose,
  DivOrgLoading,
  DivOrgResults,
  InfoResult,
  TitleSon,
} from "./NewSonStyle";
import { useForm } from "react-hook-form";
import { Close } from "@styled-icons/material";

import {
  createClientSon,
  getClientSon,
} from "../../../store/registers/clientSons/clientSons.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useDebounce } from "use-debounce";

export default function NewSon(props) {
  const detailClient = props.dataNewSon;

  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const [SonInfo, setSonInfo] = useState([]);

  const [nameSon, setNameSon] = useState("");
  const [SonError, setSonError] = useState([]);
  const [SonSearch] = useDebounce(nameSon, 500);
  const [disableBtn, setDisableBtn] = useState(false);

  const [loadingNewSon, setLoadingNewSon] = useState(false);
  const [dataFather] = useState({
    idClient: detailClient.idClient || "",
    Name: detailClient.clientName || "",
  });

  const newSon = async (dataSon) => {
    setLoadingNewSon(true);
    dataSon.idFather = dataFather.idClient;

    const sonCreate = await dispatch(createClientSon(dataSon));
    setSonInfo(sonCreate.payload);
    setTimeout(() => {
      setLoadingNewSon(false);
    }, 500);
  };

  const verifySon = async (Son) => {
    const infoSon = {
      nameSon: Son.toLowerCase(),
      idFather: dataFather.idClient,
    };
    const sonExists = await dispatch(getClientSon(infoSon));
    setSonError(sonExists.payload);
  };

  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = firstName.concat("", lastName);
    const formatName = fullName.split(" ");
    for (var i = 0; i < formatName.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName.join(" ");

    return result;
  };

  useEffect(() => {
    if (SonInfo.codeStatus === 200) {
      setTimeout(() => {
        reset();
        setSonInfo([]);
      }, 2500);
    }
  }, [reset, SonInfo]);

  useEffect(() => {
    if (SonSearch.length === 0) {
      setSonError(SonSearch);
    }

    if (SonSearch) {
      verifySon(SonSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SonSearch]);

  useEffect(() => {
    if (SonError.codeStatus === 200) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [SonError]);

  return (
    <DivNewSon show={props.newSonPop}>
      <DivBtnClose>
        <BtnClose
          onClick={() => {
            props.setNewSonPop(false);
            props.setLoadingClientSon(true);
          }}
        >
          <Close />
        </BtnClose>
      </DivBtnClose>
      <FormSon onSubmit={handleSubmit(newSon)}>
        <TitleSon>
          Adicionar filho(a) {parseName(dataFather.nameClient)}
        </TitleSon>
        <DivOrgSon>
          <LabelSon>Nome da mãe ou pai</LabelSon>
          <LabelSon>{parseName(dataFather.Name)}</LabelSon>
        </DivOrgSon>
        <DivOrgSon>
          <LabelSon>Nome do Filho</LabelSon>
          <InputSon
            {...register("nameSon", {
              required: true,
              onChange: (e) => {
                setNameSon(e.target.value);
              },
            })}
          />
        </DivOrgSon>
        <DivOrgSon>
          <LabelSon>Data de nascimento</LabelSon>
          <InputSon
            type="date"
            {...register("birthdaySon", {
              required: true,
            })}
          />
        </DivOrgSon>
        {SonError.codeStatus === 200 && (
          <DivOrgResults>
            <InfoResult>Esse filho já foi cadastrado com esse nome</InfoResult>
          </DivOrgResults>
        )}
        <DivBtnSon>
          <BtnRemoveSon
            type="button"
            onClick={() => {
              props.setNewSonPop(false);
            }}
          >
            Cancelar
          </BtnRemoveSon>
          <SubmitSon type="Submit" disabled={disableBtn}>
            Criar
          </SubmitSon>
        </DivBtnSon>
        {loadingNewSon ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} />
          </DivOrgLoading>
        ) : (
          (SonInfo.errorStatus && (
            <DivOrgResults>
              <InfoResult>{SonInfo.message}</InfoResult>
            </DivOrgResults>
          )) ||
          (SonInfo.successStatus && (
            <DivOrgResults>
              <InfoResult>{SonInfo.message}</InfoResult>
            </DivOrgResults>
          ))
        )}
      </FormSon>
    </DivNewSon>
  );
}
