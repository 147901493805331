import styled from "styled-components";
import { Colors, Phone_media } from "../../variable";
import { NumericFormat as Numeric } from "react-number-format";

export const DivCloseCashier = styled.div`
  width: 95%;
  height: 50%;
  padding: 10px;
  margin-bottom: 10px;
  background: ${Colors.BackgroundColors.BkComponent};
  border-radius: 25px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
  }
`;

export const TitleCloseCashier = styled.h1`
  font-size: 15px;
  display: flex;
  justify-content: center;
  margin: 10px auto;
`;

export const BtnCloseCashier = styled.button`
  width: 120px;
  height: 36px;
  background: ${Colors.ButtonsColors.Canceled};
  border-radius: 25px;
  cursor: pointer;
  &:active {
    background: ${Colors.ButtonsColors.Canceled};
    box-shadow: 0 5px ${Colors.ButtonsColors.ShadowButton};
    transform: translateY(4px);
  }
`;

export const FormClose = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const DivOrgClose = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${Phone_media.Phone_Form}
`;
export const InputBigClose = styled.input`
  width: 150px;
  height: 26px;
  padding: 10px;
  display: flex;
  border: transparent;
  background: ${Colors.BackgroundColors.BkInputs.Gray};
  border-radius: 25px;
`;

export const InfoUsername = styled.p`
  font-size: 14px;
`;

export const LabelClose = styled.label`
  display: flex;
  align-items: center;

  font-size: 14px;
`;

export const InputCashier = styled(Numeric)`
  width: 150px;
  height: 26px;
  padding: 10px;
  display: flex;
  border: transparent;
  background: ${Colors.BackgroundColors.BkInputs.Gray};
  border-radius: 25px;
`;

export const DivBtn = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const DivOrgBtn = styled.div`
  width: 100%;
  margin: 5px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const DivOrgStatus = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StatusCashier = styled.p`
  font-size: 0.8rem;
`;

export const DivOrgLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
