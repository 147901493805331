import React, { useEffect, useState } from "react";
import {
  Close,
  Edit,
  Password,
  PersonRemove,
  Search,
} from "@styled-icons/material";
import {
  BtnEdit,
  BtnRemove,
  BtnSearch,
  BtnPassword,
  DivBtnEdit,
  DivBtnFilter,
  DivIdUser,
  DivUser,
  DivUserInfo,
  DivSearch,
  DivSearchUser,
  DivTableSearch,
  DivInfo,
  TitleSearchUser,
  NameInput,
  NameLabel,
  SpanCod,
  SpanName,
  DivOrgLoading,
  DivOrgId,
  DivOrgCard,
  DivOrgInputs,
  EmailInput,
  FormatNumberText,
} from "./SearchUserStyle";
import UpdateUser from "../../Update/UpdateUser/UpdateUser";
import UpdatePassword from "../../Update/UpdatePassword/UpdatePassword";
// import InfoUser from "../Info/InfoUser/InfoUser";
import DeleteUser from "../../DeleteComponent/DeleteUser/DeleteUser";

import { ClipLoader } from "react-spinners";
import { BtnCancel, DivBtnSearch } from "../../Search/SearchProvider/SearchProviderStyle";

export default function SearchUser(props) {
  const usersInfo = props.usersInfo;

  // const [productInfo, setProductInfo] = useState([]);
  const [userPopUp, setUserPopUp] = useState(false);
  const [delUserOption, setDelUserOption] = useState();
  const [selectedUser, setSelectedUser] = useState();
  // const [selectedUserView, setSelectedUserView] = useState();
  const [filterUsername, setFilterUsername] = useState("");
  const [filterNameUser, setFilterNameuser] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterInfoUser, setFilterInfoUser] = useState([]);

  const [passwordPopUp, setPasswordPopUp] = useState(false);

  const [showList, setShowList] = useState(false);

  const [loading, setLoading] = useState();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [dataUserUpdate, setDataUserUpdate] = useState([]);
  const [dataPassUser, setDataPassUser] = useState({
    username: "",
    email: "",
    idUser: "",
  });

  const createListUser = async (dataUser) => {
    setLoading(true);
    setShowList(true);
    if (showList) {
      setFilterInfoUser(dataUser);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } else {
      fullName = firstName;
    }
    const formatName = fullName?.split(" ");
    for (var i = 0; i < formatName?.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName?.join(" ");

    return result;
  };

  const filterUser = () => {
    const filterList = usersInfo
      .filter((user) =>
        filterNameUser.length > 0
          ? user.firstName.includes(
              filterNameUser.toLocaleLowerCase()
            )
          : user
      )
      .filter((user) =>
        filterUsername.length > 0
          ? user.username.includes(filterUsername)
          : user
      )
      .filter((user) =>
        filterEmail.length > 0 ? user.email.includes(filterEmail) : user
      )

    setFilterInfoUser(filterList);
  };

  useEffect(() => {
    createListUser(usersInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersInfo]);

  useEffect(() => {
    setLoading(true);
    if (loadingUsers) {
      props.getListUsers();
    }
    setTimeout(() => {
      setLoadingUsers(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUsers]);

  return (
    <DivSearchUser>
      <DivSearch>
        <TitleSearchUser>Consulta Usuario</TitleSearchUser>
        <DivBtnFilter show={props.disableFilter}>
          <DivOrgInputs>
            <NameLabel>Nome</NameLabel>
            <NameInput
              value={filterNameUser}
              onChange={(e) => setFilterNameuser(e.target.value)}
            />
            <NameLabel>Conta do Usuario</NameLabel>
            <NameInput
              value={filterUsername}
              onChange={(e) => setFilterUsername(e.target.value)}
            />
          </DivOrgInputs>
          <DivOrgInputs>
            <NameLabel>Email</NameLabel>
            <EmailInput
              value={filterEmail}
              onChange={(e) => setFilterEmail(e.target.value)}
            />
            <DivBtnSearch>
              <BtnSearch type="button" onClick={filterUser}>
                <Search />
              </BtnSearch>
              <BtnCancel
                type="button"
                onClick={() => {
                  setFilterNameuser("");
                  setFilterUsername("");
                  setFilterEmail("");
                  setFilterInfoUser(usersInfo);
                }}
              >
                <Close />
              </BtnCancel>
            </DivBtnSearch>
          </DivOrgInputs>
        </DivBtnFilter>
      </DivSearch>
      <DivTableSearch>
        {loading ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} color={"#FFF"} />
          </DivOrgLoading>
        ) : (
          // <></>
          filterInfoUser.map((infoUser, index) => {
            return (
              <DivUser key={index}>
                <DivOrgCard>
                  <DivInfo>
                    <DivOrgId>
                      <DivIdUser>{index + 1}</DivIdUser>
                    </DivOrgId>
                    <DivUserInfo>
                      <SpanCod>Conta do Usuario: {infoUser.username}</SpanCod>
                      <SpanName>
                        Nome: {parseName(infoUser.firstName, infoUser.lastName)}
                      </SpanName>
                      <SpanName>Email: {infoUser.email}</SpanName>
                      <FormatNumberText
                        value={infoUser.phoneNumber}
                        format="(##) #####-####"
                        allowEmptyFormatting
                        mask="_"
                      />
                    </DivUserInfo>
                  </DivInfo>
                  <DivBtnEdit>
                    <BtnEdit
                      onClick={() => {
                        setUserPopUp(!userPopUp);
                        setDataUserUpdate(infoUser);
                      }}
                    >
                      <Edit />
                    </BtnEdit>
                    {userPopUp && infoUser.idUser === dataUserUpdate.idUser && (
                      <UpdateUser
                        permissions={props.permissions}
                        setLoadingUsers={setLoadingUsers}
                        dataUserUpdate={dataUserUpdate}
                        userPopUp={userPopUp}
                        setUserPopUp={setUserPopUp}
                      />
                    )}
                    <BtnPassword
                      onClick={() => {
                        setPasswordPopUp(!passwordPopUp);
                        setDataPassUser({
                          username: infoUser.username,
                          email: infoUser.email,
                          idUser: infoUser.idUser,
                        });
                      }}
                    >
                      <Password />
                    </BtnPassword>
                    {passwordPopUp &&
                      infoUser.idUser === dataPassUser.idUser && (
                        <UpdatePassword
                          setLoadingUsers={setLoadingUsers}
                          dataPassUser={dataPassUser}
                          passwordPopUp={passwordPopUp}
                          setPasswordPopUp={setPasswordPopUp}
                        />
                      )}
                    <BtnRemove
                      onClick={() => {
                        setDelUserOption(!delUserOption);
                        setSelectedUser(infoUser);
                      }}
                    >
                      <PersonRemove />
                    </BtnRemove>
                  </DivBtnEdit>
                </DivOrgCard>
                {delUserOption && infoUser.idUser === selectedUser.idUser && (
                  <DeleteUser
                    setLoadingUsers={setLoadingUsers}
                    selectedUser={selectedUser}
                    delUserOption={delUserOption}
                    setDelUserOption={setDelUserOption}
                  />
                )}
              </DivUser>
            );
          })
        )}
      </DivTableSearch>
    </DivSearchUser>
  );
}
