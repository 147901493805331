import React, { useState } from "react";
import {
  DivOrgCardDelete,
  DivOrgBtnClose,
  BtnClose,
  DivOrgLabel,
  DivOrgBtn,
  BtnConfirm,
  BtnCancel,
  LabelDelete,
  DivOrgDelete,
  InfoResult,
  DivOrgResults,
  DivOrgLoading,
} from "./DeleteCategoryStyle";
import { Close } from "@styled-icons/material";
import { downCategory } from "../../../store/registers/category/category.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

export default function DeleteCategory(props) {
  const categoryRemove = props.selectedCategory;
  const dispatch = useDispatch();
  const [deleteCategoryInfo, setDeleteCategoryInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const deleteCategory = async (dataCategory) => {
    setLoading(true);
    const idCategory = dataCategory.idCategory;
    const deleteCategory = await dispatch(downCategory(idCategory));
    
    setDeleteCategoryInfo(deleteCategory.payload);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setTimeout(() => {
      props.setLoadingCategorys(true);
      props.setDelCategoryOption(false)
    }, 4000);
  };

  return (
    <DivOrgDelete>
      <DivOrgBtnClose>
        <BtnClose onClick={() => props.setDelCategoryOption(false)}>
          <Close />
        </BtnClose>
      </DivOrgBtnClose>
      <DivOrgCardDelete showDiv={props.delCategoryOption}>
        <DivOrgLabel>
          <LabelDelete>
            Deseja apagar a categoria ({categoryRemove.categoryName})
          </LabelDelete>
        </DivOrgLabel>
        <DivOrgBtn>
          <BtnConfirm onClick={() => deleteCategory(categoryRemove)}>
            Sim
          </BtnConfirm>
          <BtnCancel onClick={() => props.setDelCategoryOption(false)}>
            Não
          </BtnCancel>
        </DivOrgBtn>
      </DivOrgCardDelete>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (deleteCategoryInfo.errorStatus && (
          <DivOrgResults>
            <InfoResult>{deleteCategoryInfo.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (deleteCategoryInfo.successStatus && (
          <DivOrgResults>
            <InfoResult>{deleteCategoryInfo.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivOrgDelete>
  );
}
