import React, { useEffect, useState } from "react";
import {
  DivCashier,
  DivSpentRow,
  DivSpentRowTwo,
  TitleCashier,
} from "./CashierStyle";
import OpenCashier from "../../components/Forms/OpenCashier/OpenCashier";
import CloseCashier from "../../components/CloseCashier/CloseCashier";
import ResumeCashier from "../../components/Resume/ResumeCashier/ResumeCashier";
import { useDispatch } from "react-redux";
import { getCashier } from "../../store/financial/cashier/cashier.actions";

export default function Cashier() {
  const dispatch = useDispatch();
  const [infoCashier, setInfoCashier] = useState();
  const [disableFilter, setDisableFilter] = useState(false);

  const getCashiersToday = async () => {
    const getCashiers = await dispatch(getCashier());
    setInfoCashier(getCashiers.payload);
  };

  useEffect(() => {
    getCashiersToday();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (infoCashier?.errorStatus) {
      setDisableFilter(true);
    } else {
      setDisableFilter(false);
    }
  }, [infoCashier]);

  return (
    <DivCashier>
      <TitleCashier>Caixa</TitleCashier>
      <DivSpentRow>
        <ResumeCashier
          infoCashier={infoCashier?.cashierInfo}
          disableFilter={disableFilter}
        />
        <DivSpentRowTwo>
          <TitleCashier>Caixa</TitleCashier>
          <OpenCashier
            infoCashier={infoCashier?.cashierInfo}
            getCashiersToday={getCashiersToday}
          />
          <CloseCashier
            infoCashier={infoCashier?.cashierInfo}
            getCashiersToday={getCashiersToday}
          />
        </DivSpentRowTwo>
      </DivSpentRow>
    </DivCashier>
  );
}
