import React, { useState } from "react";
import {
  BtnCancel,
  BtnSearch,
  DivFilter,
  DivFirstScreen,
  DivOrgBtn,
  DivOrgCompany,
  DivOrgDates,
  DivOrgScreen,
  DivOrgTitle,
  DivResumeExpense,
  DivResumeFin,
  DivResumeProfit,
  InfoFilter,
  SelectDay,
  TitleCompany,
  DivOrgFilter,
} from "./ResumeExpenseStyle";
import ExpensePayeds from "../../components/Resume/Expense/ExpensePayeds/ExpensePayeds";
import ExpenseNoPay from "../../components/Resume/Expense/ExpenseNoPay/ExpenseNoPay";
import { useDispatch } from "react-redux";
import { infoExpensePayed } from "../../store/infoCompany/expense/expense.actions";
import { Close, Search } from "@styled-icons/material";
import ExpenseGraphics from "../../components/Graphs/ExpenseGraphics/ExpenseGraphics";

export default function ResumeExpense() {
  const dispatch = useDispatch();

  const [dateStartExpense, setDateStartExpense] = useState("");
  const [dateFinishExpense, setDateFinishExpense] = useState("");

  const [statusData, setStatusData]= useState(false)
  const [loading, setLoading] = useState();

  const [dataExpense, setDataExpense] = useState([]);
  const [dataExpenseGraph, setDataExpenseGraph] = useState([]);

  const getInfoExpense = async (infoDate) => {
    setLoading(true);
    const organizeDate = {
      dateStart: infoDate[0],
      dateFinish: infoDate[1],
    };
    if (
      organizeDate.dateStart.length > 0 ||
      organizeDate.dateFinish.length > 0
    ) {
      const expenseData = await dispatch(infoExpensePayed(organizeDate));
      
      setDataExpense(expenseData.payload);
      setDataExpenseGraph(expenseData.payload);
      setStatusData(false)
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  return (
    <DivOrgCompany>
      <DivOrgTitle>
        <TitleCompany>Resumo de Despesas</TitleCompany>
      </DivOrgTitle>
      <DivOrgDates>
        <DivFilter>
          <DivOrgFilter>
            <InfoFilter>Inicio</InfoFilter>
            <SelectDay
              type="date"
              value={dateStartExpense}
              onChange={(e) => setDateStartExpense(e.target.value)}
            />
          </DivOrgFilter>
          <DivOrgFilter>
            <InfoFilter>Fim</InfoFilter>
            <SelectDay
              type="date"
              value={dateFinishExpense}
              onChange={(e) => setDateFinishExpense(e.target.value)}
            />
          </DivOrgFilter>
          <DivOrgBtn>
            <BtnSearch
              type="button"
              onClick={() =>
                getInfoExpense([dateStartExpense, dateFinishExpense])
              }
            >
              <Search />
            </BtnSearch>
            <BtnCancel
              type="button"
              onClick={() => {
                setDateStartExpense("");
                setDateFinishExpense("");
                setDataExpense([])
                setStatusData(true)
              }}
            >
              <Close />
            </BtnCancel>
          </DivOrgBtn>
        </DivFilter>
      </DivOrgDates>
      <DivOrgScreen>
        <DivFirstScreen>
          <DivResumeFin>
            <ExpensePayeds
              dataExpense={dataExpense.expensePayed}
              loading={loading}
            />
          </DivResumeFin>
          <DivResumeExpense>
            <ExpenseNoPay
              dataExpense={dataExpense.expenseNoPayed}
              loading={loading}
            />
          </DivResumeExpense>
          {/* <DivResumeStock></DivResumeStock> */}
        </DivFirstScreen>
        <DivResumeProfit>
          <ExpenseGraphics 
            expenseGraph={dataExpenseGraph.expenseGraph}
            statusData={statusData}
          />
        </DivResumeProfit>
      </DivOrgScreen>
    </DivOrgCompany>
  );
}
