import React, { useEffect, useState } from "react";
import {
  DivCategory,
  TitleCategory,
  DivScreenCategory,
} from "./CategoryRegisterStyle";
import NewCategory from "../../components/Forms/NewCategory/NewCategory";
import SearchCategory from "../../components/Search/SearchCategory/SearchCategory";

import { useDispatch } from "react-redux";
import { getAllCategory } from "../../store/registers/category/category.actions";

export default function CategoryRegister() {
  const dispatch = useDispatch();
  const [categorysInfo, setCategorysInfo] = useState([]);
  const [disableFilter, setDisableFilter]= useState(false)

  const getListCategorys = async () => {
    const listCategory = await dispatch(getAllCategory());
    setCategorysInfo(listCategory.payload);
  };

  useEffect(() => {
    getListCategorys();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categorysInfo.errorStatus === true) {
      alert(categorysInfo.message);
      setDisableFilter(true)
    } else {
      setDisableFilter(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorysInfo.errorStatus]);
  return (
    <DivCategory>
      <TitleCategory>Cadastro de Categoria de Despesa</TitleCategory>
      <DivScreenCategory>
        <NewCategory 
          getListCategorys={getListCategorys}
        />
        <SearchCategory
          categorysInfo={categorysInfo.allCategories}
          getListCategorys={getListCategorys}
          disableFilter={disableFilter}
        />
      </DivScreenCategory>
    </DivCategory>
  );
}
