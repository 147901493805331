import React, { useEffect, useState } from "react";
import {
  DivUpdateCategory,
  FormCategory,
  DivOrgCategory,
  LabelCategory,
  InputCategory,
  SubmitCategory,
  DivBtnCategory,
  BtnRemoveCategory,
  DivBtnClose,
  BtnClose,
  DivOrgLoading,
  DivOrgResults,
  InfoResult,
} from "./UpdateCategoryStyle";

import { useForm } from "react-hook-form";
import { Close } from "@styled-icons/material";

import {
  getNameCategory,
  updtCategory,
} from "../../../store/registers/category/category.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useDebounce } from "use-debounce";

export default function UpdateCategory(props) {
  const deliveryDetail = props.dataCategoryUpdate;
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [deliveryInfo, setCategoryInfo] = useState([]);
  const [nameCategory, setNameCategory] = useState("");
  const [categoryError, setCategoryError] = useState([]);
  const [nameCategorySearch] = useDebounce(nameCategory, 500);
  const [disableBtn, setDisableBtn] = useState(false);

  const [loadingUpdateCategory, setLoadingUpdateCategory] = useState();
  // const [valueCategory, setValueCategory] = useState();
  const [dataCategory, setDataCategory] = useState({
    idCategory: deliveryDetail.idCategory || "",
    categoryName: deliveryDetail.categoryName || "",
  });

  const deliveryUptd = async () => {
    setLoadingUpdateCategory(true);
    const upCategory = await dispatch(updtCategory(dataCategory));
    setCategoryInfo(upCategory.payload);
    setTimeout(() => {
      setLoadingUpdateCategory(false);
    });
    if (upCategory.payload.successStatus === true) {
      setTimeout(() => {
        props.setLoadingCategorys(true)
        props.setCategoryPopUp(false);
      }, 3000);
    }
  };

  const verifyCategory = async (nameCategory) => {
    const categoryExists = await dispatch(
      getNameCategory(nameCategory.toLowerCase())
    );
    setCategoryError(categoryExists.payload);
  };

  useEffect(() => {
    if (nameCategorySearch.length === 0) {
      setCategoryError(nameCategorySearch);
    }

    if (nameCategorySearch) {
      verifyCategory(nameCategorySearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameCategorySearch]);

  useEffect(() => {
    if (categoryError.codeStatus === 200) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [categoryError.codeStatus]);

  return (
    <DivUpdateCategory show={props.CategoryPopUp}>
      <FormCategory type="button" onSubmit={handleSubmit(deliveryUptd)}>
      <DivBtnClose>
        <BtnClose
          onClick={() => {
            props.setCategoryPopUp(false);
            props.setLoadingCategorys(true);
          }}
        >
          <Close />
        </BtnClose>
      </DivBtnClose>
        <DivOrgCategory>
          <LabelCategory>Codigo Categoria</LabelCategory>
          <LabelCategory>{dataCategory.idCategory}</LabelCategory>
        </DivOrgCategory>
        <DivOrgCategory>
          <LabelCategory>Categoria</LabelCategory>
          <InputCategory
            value={dataCategory.categoryName}
            {...register("nameCategory", {
              onChange: (e) => {
                setDataCategory({
                  ...dataCategory,
                  categoryName: e.target.value,
                });
                setNameCategory(e.target.value);
              },
            })}
          />
        </DivOrgCategory>
        {categoryError.codeStatus === 200 && (
          <DivOrgResults>
            <InfoResult>Já existe uma categoria com esse nome</InfoResult>
          </DivOrgResults>
        )}
        <DivBtnCategory>
          <BtnRemoveCategory
            type="cancel"
            onClick={() => {
              props.setCategoryPopUp(false);
            }}
          >
            Cancelar
          </BtnRemoveCategory>
          <SubmitCategory type="submit" disabled={disableBtn}>
            Salvar
          </SubmitCategory>
        </DivBtnCategory>
        {loadingUpdateCategory ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} />
          </DivOrgLoading>
        ) : (
          (deliveryInfo.errorStatus && (
            <DivOrgResults>
              <InfoResult>{deliveryInfo.message}</InfoResult>
            </DivOrgResults>
          )) ||
          (deliveryInfo.successStatus && (
            <DivOrgResults>
              <InfoResult>{deliveryInfo.message}</InfoResult>
            </DivOrgResults>
          ))
        )}
      </FormCategory>
    </DivUpdateCategory>
  );
}
