import React, { useEffect, useState } from "react";
import {
  DivOrgClientSon,
  DivBtnClose,
  BtnClose,
  NameClientSon,
  TitleInfoClientSon,
  DivInfoTable,
  DivOrgTitle,
  DivOrgLoading,
  DivCardClientSon,
  DivOrgInfoClientSon,
  DivOrgId,
  IdClientSon,
  DivOrgInfoName,
  DivOrgBtn,
  BtnEdit,
  BtnDelete,
  DivOrgCard,
} from "./InfoClientSonStyle";
import { Close, DeleteForever, Edit } from "@styled-icons/material";
import { useDispatch } from "react-redux";
import { getAllClientSonsById } from "../../../store/registers/clientSons/clientSons.actions";
import { ClipLoader } from "react-spinners";
import UpdateClientSon from "../../Update/UpdateClientSon/UpdateClientSon";
import DeleteClientSon from "../../DeleteComponent/DeleteClientSon/DeleteClientSon";

export default function InfoClientSon(props) {
  const infoClient = props.selectedClientSonView;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [showList, setShowList] = useState();
  const [infoClientSon, setInfoClientSon] = useState([]);
  const [listClientSon, setListClientSon] = useState([]);
  const [loadingClientSon, setLoadingClientSon] = useState(false);

  const [selectClientSon, setSelectClientSon] = useState([]);
  const [dataClientSon, setDataClientSon] = useState([]);
  const [clientSonPop, setClientSonPop] = useState(false);
  const [delClientSon, setDelClientSon] = useState(false);

  const getClientSonsByClientSon = async (dataClientSon) => {
    const idFather = dataClientSon.idClient;

    const getClientSons = await dispatch(getAllClientSonsById(idFather));
    setInfoClientSon(getClientSons.payload);
  };

  const createListClientSons = async (dataClientSon) => {
    setLoading(true);
    setShowList(true);
    if (showList && dataClientSon !== undefined) {
      setListClientSon(dataClientSon);
    }

    setTimeout(() => setLoading(false), 1000);
  };

  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } else {
      fullName = firstName;
    }
    const formatName = fullName?.split(" ");
    for (var i = 0; i < formatName?.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName?.join(" ");

    return result;
  };

  useEffect(() => {
    getClientSonsByClientSon(infoClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoClient]);

  useEffect(() => {
    createListClientSons(infoClientSon.infoClientSon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoClientSon]);

  useEffect(() => {
    setLoading(true);
    if (loadingClientSon) {
      props.setLoadingClientSon(true);
    }
    setTimeout(() => {
      setLoadingClientSon(false);
      setLoading(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingClientSon]);

  return (
    <DivOrgClientSon show={props.categoryView}>
      <DivBtnClose>
        <BtnClose onClick={() => props.setClientSonView(false)}>
          <Close />
        </BtnClose>
      </DivBtnClose>
      <DivOrgTitle>
        <TitleInfoClientSon>
          Filhos
        </TitleInfoClientSon>
      </DivOrgTitle>
      <DivInfoTable>
        {loading ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} color={"#FFF"} />
            Carregando Informações
          </DivOrgLoading>
        ) : (
          listClientSon.map((infoSon, index) => {
            return (
              <DivCardClientSon key={index}>
                <DivOrgCard>
                  <DivOrgInfoClientSon>
                    <DivOrgId>
                      <IdClientSon>{index + 1}</IdClientSon>
                    </DivOrgId>
                    <DivOrgInfoName>
                      <NameClientSon>
                        {parseName(infoSon.nameSon)}
                      </NameClientSon>
                      <NameClientSon>
                        Data de nascimento: {infoSon.birthdaySon}
                      </NameClientSon>
                    </DivOrgInfoName>
                  </DivOrgInfoClientSon>
                  <DivOrgBtn>
                    <BtnEdit
                      onClick={() => {
                        setClientSonPop(!clientSonPop);
                        setDataClientSon(infoSon);
                        setSelectClientSon(infoSon);
                      }}
                    >
                      <Edit />
                    </BtnEdit>
                    {clientSonPop &&
                      infoSon.idClientSon ===
                        selectClientSon.idClientSon && (
                        <UpdateClientSon
                          setLoadingClientSon={setLoadingClientSon}
                          dataClientSon={dataClientSon}
                          clientSonPop={clientSonPop}
                          setClientSonPop={setClientSonPop}
                        />
                      )}
                    <BtnDelete
                      onClick={() => {
                        setSelectClientSon(infoSon);
                        setDelClientSon(!delClientSon);
                      }}
                    >
                      <DeleteForever />
                    </BtnDelete>
                  </DivOrgBtn>
                </DivOrgCard>
                {delClientSon &&
                  infoSon.idClientSon === selectClientSon.idClientSon && (
                    <DeleteClientSon
                      setLoadingClientSon={props.setLoadingClientSon}
                      delClientSon={delClientSon}
                      setDelClientSon={setDelClientSon}
                      selectClientSon={selectClientSon}
                    />
                  )}
              </DivCardClientSon>
            );
          })
        )}
      </DivInfoTable>
    </DivOrgClientSon>
  );
}
