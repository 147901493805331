import React, { useEffect, useState } from "react";
import {
  DivTableProvider,
  DivTableInfo,
  DivProvider,
  IdProvider,
  DivProviderInfo,
  DivOrgInfo,
  DivOrgLoading,
  DivOrgProviderInfo,
  DivOrgCard,
  BtnView,
  DivBtnView,
  // BtnPayment,
  DivFilter,
  DivOrgFilter,
  DivOrgInputs,
  FilterLabel,
  CodInput,
  InputDate,
  DivOrgBtn,
  BtnSearch,
  BtnCancel,
  DivOrgId,
  InfoOrders,
  DivOrgResume,
} from "./TableInfoProviderStyle";

import { ClipLoader } from "react-spinners";
import {
  Close,
  // MonetizationOn,
  Search,
  Visibility,
} from "@styled-icons/material";

import FormatDatesFront from "../../../utils/formatDateFront.mjs";
import InfoResumeProviderDetail from "../../Info/InfoResumeProviderDetail/InfoResumeProviderDetail";

export default function TableInfoProvider(props) {
  const infoOrders = props.detailsProvider;

  const formatDate = new FormatDatesFront();

  // Estado para visualização de detalhes do pedido
  const [selectDetailView, setSelectDetailView] = useState();
  const [detailView, setDetailView] = useState(false);

  // Estado para visualização de detalhes de pagamento
  // const [selectPayView, setSelectPayView] = useState();
  // const [paymentView, setPaymentView] = useState(false);

  // Estado para filtros
  const [filterCodOrder, setFilterCodOrder] = useState("");
  const [filterDateStartOrder, setFilterDateStartOrder] = useState("");
  const [filterDateFinishOrder, setFilterDateFinishOrder] = useState("");
  const [filterInfoOrder, setFilterInfoOrder] = useState([]);

  const [loading, setLoading] = useState();
  const [showList, setShowList] = useState();

  const createListOrders = (dataProvider) => {
    setLoading(true);
    setShowList(true);
    if (showList) {
      setFilterInfoOrder(dataProvider);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const filterOrders = () => {
    const filterList = infoOrders.orderList
      .filter((order) =>
        filterCodOrder > 0 ? order.idStockEntry === filterCodOrder : order
      )
      .filter((order) =>
        filterDateStartOrder
          ? formatDate.compareDatesAfter(
              order.dateEntry,
              filterDateStartOrder
            ) >= 0
          : order
      )
      .filter((order) =>
        filterDateFinishOrder
          ? formatDate.compareDatesAfter(
              order.dateEntry,
              filterDateFinishOrder
            ) <= -0
          : order
      );

    setFilterInfoOrder(filterList);
  };

  useEffect(() => {
    createListOrders(infoOrders?.orderList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoOrders]);

  return (
    <DivOrgResume>
      <DivFilter>
        <DivOrgFilter>
          <DivOrgInputs>
            <FilterLabel>Nº do pedido</FilterLabel>
            <CodInput
              value={filterCodOrder}
              onValueChange={(values, sourceInfo) => {
                setFilterCodOrder(parseInt(values.value));
              }}
            />
          </DivOrgInputs>
          <DivOrgInputs>
            <FilterLabel>Data Inicio</FilterLabel>
            <InputDate
              type="date"
              value={filterDateStartOrder}
              onChange={(e) => setFilterDateStartOrder(e.target.value)}
            />
          </DivOrgInputs>
          <DivOrgInputs>
            <FilterLabel>Data Final</FilterLabel>
            <InputDate
              type="date"
              value={filterDateFinishOrder}
              onChange={(e) => setFilterDateFinishOrder(e.target.value)}
            />
          </DivOrgInputs>
          <DivOrgBtn>
            <BtnSearch type="button" onClick={filterOrders}>
              <Search />
            </BtnSearch>
            <BtnCancel
              type="button"
              onClick={() => {
                setFilterCodOrder("");
                setFilterDateStartOrder("");
                setFilterDateFinishOrder("");
                setFilterInfoOrder(infoOrders.orderList);
              }}
            >
              <Close />
            </BtnCancel>
          </DivOrgBtn>
        </DivOrgFilter>
      </DivFilter>
      <DivTableProvider>
        <DivTableInfo>
          {loading ? (
            <DivOrgLoading>
              <ClipLoader speedMultiplier={3} color={"#FFF"} />
            </DivOrgLoading>
          ) : (
            filterInfoOrder.map((infoOrders, index) => {
              return (
                <DivProvider key={index}>
                  <DivProviderInfo>
                    <DivOrgCard>
                      <DivOrgId>
                        <IdProvider>Cod:</IdProvider>
                        <InfoOrders>{infoOrders.idStockEntry}</InfoOrders>
                      </DivOrgId>
                      <DivOrgProviderInfo>
                        <DivOrgInfo>
                          Data da Compra: <br />
                          {infoOrders.dateEntry}
                        </DivOrgInfo>
                        <DivOrgInfo>
                          Quantidade: <br />
                          {infoOrders.newQtd}
                        </DivOrgInfo>
                        <DivOrgInfo>
                          Total <br />
                          {infoOrders?.priceTotal?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </DivOrgInfo>
                        {infoOrders.statusDelivery === "sim" && (
                          <DivOrgInfo>
                            Valor do Frete <br />
                            {infoOrders.valueDelivery.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </DivOrgInfo>
                        )}
                      </DivOrgProviderInfo>
                    </DivOrgCard>
                    <DivBtnView>
                      <BtnView
                        onClick={() => {
                          setSelectDetailView(infoOrders);
                          setDetailView(!detailView);
                          // setPaymentView(false);
                        }}
                      >
                        <Visibility />
                      </BtnView>
                      {/* <BtnPayment
                        onClick={() => {
                          setSelectPayView(infoOrders);
                          setPaymentView(!paymentView);
                          setDetailView(false);
                        }}
                      >
                        <MonetizationOn />
                      </BtnPayment> */}
                    </DivBtnView>
                  </DivProviderInfo>
                  {/* ! - Ate o momento não necessidade do uso dessa info */}
                  {/* {paymentView &&
                    infoOrders.idStockEntry === selectPayView.idStockEntry && (
                      <InfoResumeProviderPayment
                        selectPayView={selectPayView}
                        paymentView={paymentView}
                        setPaymentView={setPaymentView}
                      />
                    )} */}
                  {detailView &&
                    infoOrders.idStockEntry ===
                      selectDetailView.idStockEntry && (
                      <InfoResumeProviderDetail
                        selectDetailView={selectDetailView}
                        detailView={detailView}
                        setDetailView={setDetailView}
                      />
                    )}
                </DivProvider>
              );
            })
          )}
        </DivTableInfo>
      </DivTableProvider>
    </DivOrgResume>
  );
}
