import styled from "styled-components";
import { Colors, Phone_media, Tablet_media } from "../../../variable";
import { NumericFormat as Numeric } from "react-number-format";

export const DivOpenCashier = styled.div`
  width: 95%;
  height: 45%;
  padding: 10px;
  margin-bottom: 10px;

  background: ${Colors.BackgroundColors.BkComponent};
  border-radius: 25px;
  ${Phone_media.Phone_column}
  ${Tablet_media.Tablet_column}
`;
export const FormCash = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleOpenCashier = styled.h1`
  font-size: 15px;
  display: flex;
  justify-content: center;
  margin: 10px auto;
`;

export const DivHour = styled.div``;
export const InfoHour = styled.h6``;

export const BtnOpenCashier = styled.button`
  width: 120px;
  height: 36px;
  background: ${Colors.ButtonsColors.Confirm};
  border-radius: 25px;
  cursor: pointer;

  &:active {
    background: ${Colors.ButtonsColors.Confirm};
    box-shadow: 0 5px ${Colors.ButtonsColors.ShadowButton};
    transform: translateY(4px);
  }
`;

export const DivOrgOpenCashier = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${Phone_media.Phone_Form}
`;
export const InfoUsername = styled.p`
  font-size: 14px;
`;
export const LabelOpenCashier = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const InputCashier = styled(Numeric)`
  width: 150px;
  height: 26px;
  padding: 10px;
  display: flex;
  border: transparent;
  background: ${Colors.BackgroundColors.BkInputs.Gray};
  border-radius: 25px;
`;

export const DivBtn = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const DivOrgBtn = styled.div`
  width: 100%;
  margin: 5px auto;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const DivOrgStatus = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const StatusCashier = styled.p`
  font-size: 0.8rem;
`;

export const DivOrgLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
