import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getInfoClientSon,
  newClientSon,
  updateClientSon,
  deleteClientSon,
  getClientSonsById,
} from "../../../api/routes/register/clientSonRegister";

export const getAllClientSonsById = createAsyncThunk(
  "son/all-son",
  async (infoClientSon, { rejectWithValue }) => {
    const response = await getClientSonsById(infoClientSon);
    if (response.erroStatus === true) {
      return rejectWithValue(response);
    }
    return response;
  }
);
export const getClientSon = createAsyncThunk(
  "son/id-son",
  async (infoFather, { rejectWithValue }) => {
    const response = await getInfoClientSon(infoFather);
    if (response.errorStatus === true) {
      return rejectWithValue(response);
    }

    return response;
  }
);

export const createClientSon = createAsyncThunk(
  "son/new-son",
  async (datason, { rejectWithValue }) => {
    const response = await newClientSon(datason);
    if (response.errorStatus === true) {
      return rejectWithValue(response);
    }

    return response;
  }
);
export const updtClientSon = createAsyncThunk(
  "son/up-son",
  async (data, { rejectWithValue }) => {
    const response = await updateClientSon(data);
    if (response.errorStatus === true) {
      return rejectWithValue(response);
    }
    return response;
  }
);
export const downClientSon = createAsyncThunk(
  "son/down-son",
  async (idClientSon, { rejectWithValue }) => {
    const response = await deleteClientSon(idClientSon);
    if (response.errorStatus === true) {
      return rejectWithValue(response);
    }

    return response;
  }
);
