// import { Add, Delete } from "@styled-icons/material";
import React, { useEffect, useState } from "react";
import {
  DivNewProduct,
  FormProduct,
  DivFormProduct,
  DivOrgProduct,
  LabelProduct,
  InputProduct,
  InputProductName,
  DivBtnProduct,
  BtnRemoveProduct,
  SubmitProduct,
  DivOrgResults,
  InfoResult,
  BtnCreateBrand,
  DivBtnBrand,
  DivOrgLoading,
} from "./NewProductStyle";

import { useForm } from "react-hook-form";
import {
  createProduct,
  getCodProduct,
  getNameProduct
} from "../../../store/registers/products/products.actions";
import {
  getBrandInfo,
  createBrand,
} from "../../../store/registers/brand/brand.actions";
import { useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Add } from "@styled-icons/material";
import { ClipLoader } from "react-spinners";

export default function NewProduct(props) {
  const dispatch = useDispatch();
  const [priceBuyProduct, setPriceBuyProduct] = useState();
  const [priceSellProduct, setPriceSellProduct] = useState();
  const [percentSellProduct, setPercentSellProduct] = useState();

  const [nameBrand, setNameBrand] = useState();
  const [brandInfo, setBrandInfo] = useState([]);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [nameBrandSearch] = useDebounce(nameBrand, 500);
  const [loadingListProducts, setLoadingListProducts] = useState(false);
  const [loading, setLoading] = useState();

  const [codProduct, setCodProduct] = useState();
  const [productInfo, setProductInfo] = useState([]);
  const [productCreated, setProductCreated] = useState([]);
  const [nameProduct, setNameProduct] = useState("");
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [productSearch] = useDebounce(nameProduct, 500);
  const [buttonHide, setButtonHide] = useState(false);

  const { register, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: {
      percentSell: "",
    },
  });

  const getBrand = async (dataBrand) => {
    setLoadingBrand(true);
    const nameBrandInfo = await dispatch(getBrandInfo(dataBrand));
    setBrandInfo(nameBrandInfo.payload);
    setLoadingBrand(false);
  };

  const getNewCodProduct = async () => {
    const newCod = await dispatch(getCodProduct());
    setCodProduct(newCod.payload);
  };

  const getProductName = async (dataProduct) => {
    setLoadingProduct(true);
    const nameProductInfo = await dispatch(getNameProduct(dataProduct));
    setProductInfo(nameProductInfo.payload);
    setLoadingProduct(false);
  };

  const newBrand = async (dataBrand) => {
    const brand = {
      brandName: dataBrand.toLowerCase(),
    };
    const brandCreate = await dispatch(createBrand(brand));

    setBrandInfo(brandCreate.payload);
  };

  const newProduct = async (dataProduct) => {
    setLoading(true);
    dataProduct.priceBuy = priceBuyProduct;
    dataProduct.priceSell = priceSellProduct;
    dataProduct.percentSell = percentSellProduct;
    dataProduct.idBrand = brandInfo.brand.idBrand;
    dataProduct.codProd = codProduct.newCodProduct;
    const productCreate = await dispatch(createProduct(dataProduct));
    setProductCreated(productCreate.payload);
    setTimeout(() => {
      setLoadingListProducts(true);
      setLoading(false);
    }, 1000);
  };

  const disableScroll = (e) => {
    e.target.addEventListener(
      "wheel",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  };

  useEffect(() => {
    getNewCodProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const priceBuy = priceBuyProduct;
  const priceSell = priceSellProduct;

  useEffect(() => {
    if (productSearch?.length === 0) {
      setProductInfo(productSearch);
    } else {
      getProductName(productSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSearch]);

  useEffect(() => {
    if (nameBrandSearch?.length === 0) {
      setBrandInfo(nameBrandSearch);
    }

    if (nameBrandSearch) {
      getBrand(nameBrandSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameBrandSearch]);

  useEffect(() => {
    // Calcula o percentual de lucro com base no preço de compra e no preço de venda
    let percentProduct =
      ((parseFloat(priceSell) - parseFloat(priceBuy)) / parseFloat(priceBuy)) *
      100;
    // Atualiza o estado ou formulário com o valor calculado
    setValue("percentSell", parseFloat(percentProduct.toFixed(2))); // Arredonda para 2 casas decimais
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceSell, priceBuy, setValue]);

  useEffect(() => {
    if (productInfo.successStatus === true) {
      setButtonHide(true);
    } else {
      setButtonHide(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo]);

  useEffect(() => {
    if (loadingListProducts) {
      props.getListProducts();
    }
    setTimeout(() => {
      setLoadingListProducts(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingListProducts]);

  useEffect(() => {
    if (productCreated.codeStatus === 200) {
      setTimeout(() => {
        reset();
        setPriceBuyProduct("");
        setPercentSellProduct("");
      }, 3000);
      setTimeout(() => {
        setBrandInfo([]);
        // setBrandRegister([]);
        setProductCreated([]);
        setProductInfo([]);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCreated]);

  return (
    <DivNewProduct>
      <FormProduct onSubmit={handleSubmit(newProduct)}>
        <DivFormProduct>
          <DivOrgProduct>
            <LabelProduct>Codigo do Produto</LabelProduct>
            <LabelProduct>{codProduct?.newCodProduct}</LabelProduct>
          </DivOrgProduct>

          <DivOrgProduct>
            <LabelProduct>Nome</LabelProduct>
            <InputProductName
              type="text"
              maxLength={100}
              {...register("nameProduct")}
              onChange={(e) => {
                setNameProduct(e.target.value);
              }}
            />
          </DivOrgProduct>
          {loadingProduct ? (
            <DivOrgLoading>
              <ClipLoader speedMultiplier={3} />
            </DivOrgLoading>
          ) : (
            productInfo.successStatus && (
              <DivOrgResults>
                <InfoResult>
                  Produto já cadastrado, use outro nome para cadastrar esse
                  produto.
                </InfoResult>
              </DivOrgResults>
            )
          )}
          <DivOrgProduct>
            <LabelProduct>Preço de Compra</LabelProduct>
            <NumericFormat
              customInput={InputProduct}
              value={priceBuyProduct}
              placeholder="R$"
              allowEmptyFormatting
              mask="_"
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale
              decimalScale={2}
              prefix={"R$"}
              onValueChange={(values, sourceInfo) => {
                setPriceBuyProduct(Number(values.value));
              }}
            />
          </DivOrgProduct>

          <DivOrgProduct>
            <LabelProduct>Preço de Venda</LabelProduct>
            <NumericFormat
              customInput={InputProduct}
              placeholder="R$"
              allowEmptyFormatting
              mask="_"
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale
              decimalScale={2}
              prefix={"R$"}
              onValueChange={(values, sourceInfo) => {
                setPriceSellProduct(Number(values.value));
              }}
            />
          </DivOrgProduct>
          <DivOrgProduct>
            <LabelProduct>Porcentagem de Venda (%)</LabelProduct>
            <PatternFormat
              displayType="text"
              value={watch("percentSell") || ""}
              format="######%"
              allowEmptyFormatting
              placeholder="%"
              isAllowed={(values) => {
                if (!values.value) return true;
                const { floatValue } = values;
                return floatValue <= 100;
              }}
              onValueChange={(values, sourceInfo) => {
                setPercentSellProduct(values.value);
              }}
            />
          </DivOrgProduct>
          <DivOrgProduct>
            <LabelProduct>Marca</LabelProduct>
            {/* Buscar uma marca */}
            <InputProduct
              {...register("idBrand", {})}
              onFocus={disableScroll}
              onChange={(e) => {
                setNameBrand(e.target.value);
              }}
            />
          </DivOrgProduct>
          {loadingBrand ? (
            <DivOrgLoading>
              <ClipLoader speedMultiplier={3} />
            </DivOrgLoading>
          ) : (
            brandInfo.errorStatus && (
              <DivOrgResults>
                <InfoResult>{brandInfo.message}</InfoResult>
                <DivBtnBrand>
                  <BtnCreateBrand
                    type="button"
                    onClick={() => newBrand(nameBrandSearch)}
                  >
                    <Add />
                  </BtnCreateBrand>
                </DivBtnBrand>
              </DivOrgResults>
            )
          )}
          {brandInfo.successStatus && (
            <DivOrgResults>
              <InfoResult>{brandInfo.message}</InfoResult>
            </DivOrgResults>
          )}
          <DivBtnProduct>
            <BtnRemoveProduct type="reset" onClick={() => setBrandInfo([])}>
              Cancelar
            </BtnRemoveProduct>
            <SubmitProduct type="submit" disabled={buttonHide}>
              Cadastrar
            </SubmitProduct>
          </DivBtnProduct>
        </DivFormProduct>
      </FormProduct>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (productCreated.errorStatus && (
          <DivOrgResults>
            <InfoResult>{productCreated.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (productCreated.successStatus && (
          <DivOrgResults>
            <InfoResult>{productCreated.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivNewProduct>
  );
}
