import React, { useState } from "react";
import {
  DivUpdateBrand,
  FormBrand,
  DivOrgBrand,
  LabelBrand,
  InputBrand,
  SubmitBrand,
  DivBtnBrand,
  BtnRemoveBrand,
  DivBtnClose,
  BtnClose,
  DivOrgLoading,
  DivOrgResults,
  InfoResult,
} from "./UpdateBrandStyle";
// import {  } from "./UpdateBrandStatus";
import { useForm } from "react-hook-form";
import { Close } from "@styled-icons/material";

import { updtBrand } from "../../../store/registers/brand/brand.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

export default function UpdateBrand(props) {
  const brandDetail = props.dataBrandUpdate;
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [loadingUpdateBrand, setLoadingUpdateBrand] = useState();
  const [brandInfo, setBrandInfo] = useState([]);
  const [dataBrand, setDataBrand] = useState({
    brandName: brandDetail.brandName || "",
  });

  const brandUptd = async (upBrand) => {
    setLoadingUpdateBrand(true);
    upBrand.idBrand = brandDetail.idBrand;
    upBrand.brandName = dataBrand.brandName.toLowerCase();
    const changeBrand = await dispatch(updtBrand(upBrand));
    setBrandInfo(changeBrand.payload);
    setTimeout(() => {
      setLoadingUpdateBrand(false);
    }, 1000);
    if (changeBrand.payload.successStatus === true) {
      setTimeout(() => {
        props.setLoadingBrands(true);
        props.setBrandPopUp(false);
      }, 3000);
    }
  };
  return (
    <DivUpdateBrand show={props.brandPopUp}>
      <FormBrand onSubmit={handleSubmit(brandUptd)}>
      <DivBtnClose>
        <BtnClose type="button" onClick={() => props.setBrandPopUp(false)}>
          <Close />
        </BtnClose>
      </DivBtnClose>
        <DivOrgBrand>
          <LabelBrand>Nome</LabelBrand>

          <InputBrand
            value={dataBrand.brandName}
            {...register("brandName", {
              onChange: (e) => {
                setDataBrand({
                  ...dataBrand,
                  brandName: e.target.value,
                });
              },
            })}
          />
        </DivOrgBrand>
        <DivBtnBrand>
          <BtnRemoveBrand
            type="button"
            onClick={() => props.setBrandPopUp(false)}
          >
            Cancelar
          </BtnRemoveBrand>
          <SubmitBrand type="submit">Salvar</SubmitBrand>
        </DivBtnBrand>
        {loadingUpdateBrand ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} />
          </DivOrgLoading>
        ) : (
          (brandInfo.errorStatus && (
            <DivOrgResults>
              <InfoResult>{brandInfo.message}</InfoResult>
            </DivOrgResults>
          )) ||
          (brandInfo.successStatus && (
            <DivOrgResults>
              <InfoResult>{brandInfo.message}</InfoResult>
            </DivOrgResults>
          ))
        )}
      </FormBrand>
    </DivUpdateBrand>
  );
}
