import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import { Twirl } from "hamburger-react";
import { Colors } from "../../variable";

export const DivNav = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

export const ImgLogo = styled.img`
  width: 100%;
  height: 100px;
`;

export const Nav = styled.div`
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem calc((10vw - 1000px) / 2);
  z-index: 10;
  @media screen and (min-width: 320px) and (max-width: 940px) {
    margin-bottom: ${({ open }) => (open ? "280px" : "0")};
    transition: margin-bottom 0.3s ease-in;
  }
`;

export const DivDrop = styled.div`
  display: none;
  position: absolute;
  background-color: #6b6b6b;
  min-width: 50px;
  height: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px ${Colors.BackgroundColors.BKBar};
  }
`;

export const NavLinkImg = styled(Link)`
  margin-top: 15px;
`;

export const OptionMenu = styled.ul`
  color: ${Colors.Text.Black};
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;

  padding: 0 2rem;

  &.active {
    color: ${Colors.BackgroundColors.BkCards.Info};
  }

  @media screen and (min-width: 320px) and (max-width: 940px) {
    margin-bottom: 15px;
    padding: 3px;
    margin: 10px;

    &:active ${DivDrop} {
      width: 140px;
      position: fixed;
      left: 220px;
    }
  }
`;

export const DivImg = styled.div`
  width: 100%;
  height: 100%;

  border: 5px solid black;
`;

export const Bars = styled.div`
  display: none;

  @media screen and (min-width: 320px) and (max-width: 940px) {
    display: flex;
    padding: 15px;
    margin: 10px;
    top: 0;
    right: 0;
    outline: none;
    font-size: 1.8rem;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
`;

export const Bar = styled(Twirl)`
  display: block;
  width: 25px;
  height: 3px;
  margin: 3px auto;
  background-color: ${Colors.BackgroundColors.BKBar};
`;

export const NavMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 10px;
  align-items: center;
  margin-right: 24px;

  @media screen and (min-width: 320px) and (max-width: 940px) {
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    left: ${({ open }) => (open ? "0%" : "-100%")};
    top: 5.5rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 25%;
    margin: 10px 0px;
    border-radius: 10px;
    text-align: center;
    -webkit-transition: 0.3s;
    transition: 0.3s ease-in;
    background-color: none;
  }
`;

export const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (min-width: 320px) and (max-width: 940px) {
    display: none;
    margin-top: 10px;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 25px;
  background: ${Colors.BackgroundColors.BKBar};
  padding: 10px 22px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 24px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;

export const DropMenuSpan = styled(Link)`
  font-size: 12px;
  margin: 10px;
  text-decoration: none;
  color: #fff;

  &:hover {
    color: ${Colors.BackgroundColors.BkComponent};
  }
`;

export const LiDiv = styled.li`
  display: flex;
  justify-content: center;
  &:hover ${DivDrop} {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    border-radius: 25px;
  }
  @media screen and (min-width: 320px) and (max-width: 940px) {
    /* border: 1px solid black; */
    &:hover ${DivDrop} {
      display: flex;
      flex-direction: column;
      margin-top: 35px;
      margin-left: 255px;
      border-radius: 25px;
    }
  }
`;

export const DivOrgMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 320px) and (max-width: 940px) {
    flex-direction: column;
  }
`;
